import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useSelector, shallowEqual } from "react-redux";
import { toastr } from "react-redux-toastr";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ExcelJS from "exceljs/dist/es5/exceljs.browser";
import { Modal, Row, Col } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import AgGridTable from "../../../app/Modules/Table/AgGridTable";
import TableSpinner from "../../../app/Modules/Helpers/TableSpinner";
import TableCardHeader from "../../../app/Modules/Table/TableCardHeader";
import saveAs from "file-saver";
import { populateColumn } from "../../../app/Modules/Helpers/ExcelJSUtils";

import { GET_PREVIOUS_FISCAL_YEAR } from "../../../app/Modules/Administration/_Commons/Constants/Queries/FiscalYears";
import {
  GET_DASHBOARD_REPORTS_GRID_DATA,
  GET_DASHBOARD_REPORTS_GRID_DATA_VOTE,
  GET_DASHBOARD_REPORTS_GRID_DATA_SEPC,
  GET_DASHBOARD_REPORTS_GRID_DATA_LG_VOTE,
  GET_DASHBOARD_REPORT_DETAILS,
} from "../../../app/Modules/Administration/_Commons/Constants/Queries/DashboardReports";
import {
  StatsWidget11,
  // StatsWidget12, AdvanceTablesWidget3, StatsTiles,
  MixedWidget2,
  MixedWidget3,
} from "../widgets";

export function Demo1Dashboard() {
  let {
    user,
    activeFiscalYear,
    // previousFiscalYear
  } = useSelector((state) => state.auth, shallowEqual);
  activeFiscalYear = activeFiscalYear === undefined ? { Year_ID: 0 } : activeFiscalYear;
  const User_Name = user === undefined ? "" : user.User_Name;
  const mainTabs = {
    SubmissionStatus: "SubmissionStatus",
    BudgetStatistics: "BudgetStatistics",
  };

  const statusTabs = {
    CgVote: "CgVote",
    CgProgramme: "CgProgramme",
    SEPC: "SEPC",
    LgVote: "LgVote",
  };

  const [PreviousYear_ID, setPreviousYear_ID] = useState(0);
  // const [PreviousYear_ID] = useState(previousFiscalYear && previousFiscalYear.Year_ID ? previousFiscalYear.Year_ID : 0);
  const [CgVoteRowData, setCgVoteRowData] = useState([]);
  const [CgVoteRowDataLoading, setCgVoteRowDataLoading] = useState(true);
  const [CgProgrammeRowData, setCgProgrammeRowData] = useState([]);
  const [CgProgrammeRowDataLoading, setCgProgrammeRowDataLoading] = useState(true);
  const [SEPCRowData, setSEPCRowData] = useState([]);
  const [SEPCRowDataLoading, setSEPCRowDataLoading] = useState(true);
  const [LgVoteRowData, setLgVoteRowData] = useState([]);
  const [LgVoteRowDataLoading, setLgVoteRowDataLoading] = useState(true);
  const [ReportDetailsRowData, setReportDetailsRowData] = useState([]);
  const [ReportDetailsRowDataLoading, setReportDetailsRowDataLoading] = useState(true);
  const [ShowDetails, setShowDetails] = useState(false);

  const [mainKey, setMainKey] = useState(mainTabs.SubmissionStatus);
  const [key, setKey] = useState(statusTabs.CgVote);

  const [Report_Category, setReport_Category] = useState("");
  const [Report_Type, setReport_Type] = useState("");
  const [Report_Name, setReport_Name] = useState("");
  const [Report_ID, setReport_ID] = useState(0);
  const [Output, setOutput] = useState("");

  const handleGetPreviousFiscalYear = useQuery(GET_PREVIOUS_FISCAL_YEAR);

  useEffect(() => {
    const { loading, error, data } = handleGetPreviousFiscalYear;
    if (error) toastr.error("Failed to fetch previous year", error.message);

    if (!loading && data) {
      data.previousFiscalYear && setPreviousYear_ID(data.previousFiscalYear.Year_ID);
    }
  }, [handleGetPreviousFiscalYear]);

  const handleGetCgVoteDashboardReports = useQuery(GET_DASHBOARD_REPORTS_GRID_DATA_VOTE, {
    variables: {
      Report_Category: "CG",
      Report_Type: "VOTE",
      Active_Year_ID: activeFiscalYear.Year_ID,
      Previous_Year_ID: PreviousYear_ID,
    },
    skip: !(activeFiscalYear && PreviousYear_ID && mainKey === mainTabs.SubmissionStatus && key === statusTabs.CgVote),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetCgVoteDashboardReports;
    setCgVoteRowDataLoading(loading);
    if (error) toastr.error("Failed to fetch CG Vote report data", error.message);
    if (!loading && data) {
      setCgVoteRowData(data.dashboardReportsGridDataVote);
    }
  }, [handleGetCgVoteDashboardReports, mainKey, key]);

  const handleGetCgProgrammeDashboardReports = useQuery(GET_DASHBOARD_REPORTS_GRID_DATA, {
    variables: {
      Report_Category: "CG",
      Report_Type: "PROGRAMME",
      Active_Year_ID: activeFiscalYear.Year_ID,
      Previous_Year_ID: PreviousYear_ID,
    },
    skip: !(activeFiscalYear && PreviousYear_ID && mainKey === mainTabs.SubmissionStatus && key === statusTabs.CgProgramme),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetCgProgrammeDashboardReports;
    setCgProgrammeRowDataLoading(loading);
    if (error) toastr.error("Failed to fetch CG Programme report data", error.message);
    if (!loading && data) {
      setCgProgrammeRowData(data.dashboardReportsGridData);
    }
  }, [handleGetCgProgrammeDashboardReports, mainKey, key]);

  const handleGetSEPCDashboardReports = useQuery(GET_DASHBOARD_REPORTS_GRID_DATA_SEPC, {
    variables: {
      Report_Category: "SEPC",
      Report_Type: "Parastatal",
      Active_Year_ID: activeFiscalYear.Year_ID,
      Previous_Year_ID: PreviousYear_ID,
    },
    skip: !(activeFiscalYear && PreviousYear_ID && mainKey === mainTabs.SubmissionStatus && key === statusTabs.SEPC),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetSEPCDashboardReports;
    setSEPCRowDataLoading(loading);
    if (error) toastr.error("Failed to fetch SEPC report data", error.message);
    if (!loading && data) {
      setSEPCRowData(data.dashboardReportsGridDataSepc);
    }
  }, [handleGetSEPCDashboardReports, mainKey, key]);

  const handleGetLgVoteDashboardReports = useQuery(GET_DASHBOARD_REPORTS_GRID_DATA_LG_VOTE, {
    variables: {
      Report_Category: "LG",
      Report_Type: "Vote",
      Active_Year_ID: activeFiscalYear.Year_ID,
      Previous_Year_ID: PreviousYear_ID,
    },
    skip: !(activeFiscalYear && PreviousYear_ID && mainKey === mainTabs.SubmissionStatus && key === statusTabs.LgVote),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetLgVoteDashboardReports;
    setLgVoteRowDataLoading(loading);
    if (error) toastr.error("Failed to fetch LG Vote report data", error.message);
    if (!loading && data) {
      setLgVoteRowData(data.dashboardReportsGridDataLgVote);
    }
  }, [handleGetLgVoteDashboardReports, mainKey, key]);

  const handleGetDashboardReportDetails = useQuery(GET_DASHBOARD_REPORT_DETAILS, {
    variables: {
      Report_Category,
      Report_Type,
      Active_Year_ID: activeFiscalYear.Year_ID,
      Previous_Year_ID: PreviousYear_ID,
      Report_ID,
      Output,
    },
    skip: !(
      Report_Category &&
      Report_Type &&
      activeFiscalYear &&
      PreviousYear_ID &&
      Report_ID &&
      Output &&
      mainKey === mainTabs.SubmissionStatus
    ),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetDashboardReportDetails;
    setReportDetailsRowDataLoading(loading);
    if (error) toastr.error("Failed to fetch report details data", error.message);
    if (!loading && data) {
      setReportDetailsRowData(data.dashboardReportDetails);
    }
  }, [handleGetDashboardReportDetails, Report_Category, Report_Type, Report_ID, Output]);

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
    },
    columnDefs: [
      {
        headerName: "Group_ID",
        field: "Group_ID",
        resizable: false,
        hide: true,
      },
      { headerName: "Report Name", field: "Report_Name" },
      { headerName: "Consolidated", field: "Consolidated" },

      { headerName: "Not Initiated", field: "NotInitiated" },

      // { headerName: "Initiated", field: "Initiated" },
      { headerName: "Initiated", field: "InProgress" },
      { headerName: "Rejected", field: "Rejected" },
      { headerName: "Approved", field: "Approved" },
    ],
  };

  const reportDetailsGridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
    },
    columnDefs:
      Report_Type === "PROGRAMME"
        ? [
            { headerName: "Programme Code", field: "Programme_Code" },
            { headerName: "Programme Name", field: "Programme_Name" },

            { headerName: "Task Status", field: "Task_Status" },
            { headerName: "Initiator Status", field: "Initiator_Status" },
            { headerName: "First Level Status", field: "First_Level_Status" },
            { headerName: "Second Level Status", field: "Second_Level_Status" },
            { headerName: "Third Level Status", field: "Third_Level_Status" },
            { headerName: "Fourth Level Status", field: "Fourth_Level_Status" },
            { headerName: "Fifth Level Status", field: "Fifth_Level_Status" },
            { headerName: "Sixth Level Status", field: "Sixth_Level_Status" },
            { headerName: "Seventh Level Status", field: "Seventh_Level_Status" },
          ]
        : Report_Type === "Parastatal"
        ? [
            { headerName: "Parastatal Code", field: "Parastatal_Code" },
            { headerName: "Parastatal Name", field: "Parastatal_Name" },

            { headerName: "Task Status", field: "Task_Status" },
            { headerName: "Initiator Status", field: "Initiator_Status" },
            { headerName: "First Level Status", field: "First_Level_Status" },
            { headerName: "Second Level Status", field: "Second_Level_Status" },
            { headerName: "Third Level Status", field: "Third_Level_Status" },
            { headerName: "Fourth Level Status", field: "Fourth_Level_Status" },
            { headerName: "Fifth Level Status", field: "Fifth_Level_Status" },
            { headerName: "Sixth Level Status", field: "Sixth_Level_Status" },
            { headerName: "Seventh Level Status", field: "Seventh_Level_Status" },
          ]
        : [
            { headerName: "Vote Code", field: "Vote_Code" },
            { headerName: "Vote Name", field: "Vote_Name" },
            { headerName: "Task Status", field: "Task_Status" },
            { headerName: "Initiator Status", field: "Initiator_Status" },
            { headerName: "First Level Status", field: "First_Level_Status" },
            { headerName: "Second Level Status", field: "Second_Level_Status" },
            { headerName: "Third Level Status", field: "Third_Level_Status" },
            { headerName: "Fourth Level Status", field: "Fourth_Level_Status" },
            { headerName: "Fifth Level Status", field: "Fifth_Level_Status" },
            { headerName: "Sixth Level Status", field: "Sixth_Level_Status" },
            { headerName: "Seventh Level Status", field: "Seventh_Level_Status" },
          ],
  };

  const onSwitchTabs = (tab) => {
    setKey(tab);
  };

  const onSwitchMainTabs = (tab) => {
    setMainKey(tab);
  };

  const onCellClicked = (e) => {
    if (!["Report_Name", "Consolidated"].includes(e.colDef.field)) {
      switch (key) {
        case "CgVote":
          setReport_Category("CG");
          setReport_Type("VOTE");
          break;
        case "CgProgramme":
          setReport_Category("CG");
          setReport_Type("PROGRAMME");
          break;
        case "SEPC":
          setReport_Category("SEPC");
          setReport_Type("Parastatal");
          break;
        case "LgVote":
          setReport_Category("LG");
          setReport_Type("VOTE");
          break;
        default:
          break;
      }
      setReport_ID(e.data.Report_ID);
      setReport_Name(e.data.Report_Name);
      setOutput(e.colDef.field);
      setShowDetails(true);
    }
  };

  const populateAndDownloadTemplate = () => {
    const ExcelJSWorkbook = new ExcelJS.Workbook();
    ExcelJSWorkbook.views = [{ activeTab: 1 }];
    const masterSheet = ExcelJSWorkbook.addWorksheet("Report Data");

    const VoteCodes = ReportDetailsRowData.map((d) => d.Vote_Code);
    const VoteNames = ReportDetailsRowData.map((d) => d.Vote_Name);
    const ProgrammeCodes = ReportDetailsRowData.map((d) => d.Programme_Code);
    const ProgrammeNames = ReportDetailsRowData.map((d) => d.Programme_Name);
    const ParastatalCodes = ReportDetailsRowData.map((d) => d.Parastatal_Code);
    const ParastatalNames = ReportDetailsRowData.map((d) => d.Parastatal_Name);
    const SubVoteNames = ReportDetailsRowData.map((d) => d.Task_Status);
    const SubProgrammeNames = ReportDetailsRowData.map((d) => d.Initiator_Status);
    const Sub_SubVoteNames = ReportDetailsRowData.map((d) => d.First_Level_Status);
    const Sub_SubProgrammeNames = ReportDetailsRowData.map((d) => d.Second_Level_Status);
    const DepartmentCodes = ReportDetailsRowData.map((d) => d.Third_Level_Status);
    const DepartmentNames = ReportDetailsRowData.map((d) => d.Fourth_Level_Status);
    const ProjectCodes = ReportDetailsRowData.map((d) => d.Fifth_Level_Status);
    const ProjectNames = ReportDetailsRowData.map((d) => d.Sixth_Level_Status);
    const Budget_OutputCodes = ReportDetailsRowData.map((d) => d.Seventh_Level_Status);

    populateColumn(
      masterSheet,
      "A",
      Report_Type === "PROGRAMME" ? "Programme Code" : Report_Type === "Parastatal" ? "Parastatal Code" : "Vote Code",
      20,
      Report_Type === "PROGRAMME" ? ProgrammeCodes : Report_Type === "Parastatal" ? ParastatalCodes : VoteCodes
    );
    populateColumn(
      masterSheet,
      "B",
      Report_Type === "PROGRAMME" ? "Programme Name" : Report_Type === "Parastatal" ? "Parastatal Name" : "Vote Name",
      20,
      Report_Type === "PROGRAMME" ? ProgrammeNames : Report_Type === "Parastatal" ? ParastatalNames : VoteNames
    );
    populateColumn(masterSheet, "C", "Task Status", 20, SubVoteNames);
    populateColumn(masterSheet, "D", "Initiator Status", 20, SubProgrammeNames);
    populateColumn(masterSheet, "E", "First Level Status", 20, Sub_SubVoteNames);
    populateColumn(masterSheet, "F", "Second Level Status", 20, Sub_SubProgrammeNames);
    populateColumn(masterSheet, "G", "Third Level Status", 20, DepartmentCodes);
    populateColumn(masterSheet, "H", "Fourth Level Status", 20, DepartmentNames);
    populateColumn(masterSheet, "I", "Fifth Level Status", 20, ProjectCodes);
    populateColumn(masterSheet, "J", "Sixth Level Status", 20, ProjectNames);
    populateColumn(masterSheet, "K", "Seventh Level Status", 20, Budget_OutputCodes);

    const today = new Date();
    const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
    const time = `${today.getHours()}hrs${today.getMinutes()}min${today.getSeconds()}sec`;
    ExcelJSWorkbook.xlsx.writeBuffer().then(function(buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${User_Name}_${Report_Category}-${Report_Type}_Report_Submission_Details_${date}_${time}.xlsx`
      );
    });
  };
  return (
    <>
      <div className="content-bg pad10" style={{ minHeight: 300 }}>
        <Tabs id="main-dash-tabs" activeKey={mainKey} onSelect={onSwitchMainTabs}>
          <Tab eventKey="SubmissionStatus" title="Submission Status">
            <div className="ag-theme-material mt-10">
              <Tabs id="controlled-tab-example" activeKey={key} onSelect={onSwitchTabs}>
                <Tab eventKey="CgVote" title="CG Vote">
                  {CgVoteRowDataLoading ? (
                    <TableSpinner />
                  ) : (
                    <AgGridTable rowData={CgVoteRowData} gridOptions={gridOptions} onCellClicked={onCellClicked} />
                  )}
                </Tab>
                <Tab eventKey="CgProgramme" title="CG Programme">
                  {CgProgrammeRowDataLoading ? (
                    <TableSpinner />
                  ) : (
                    <AgGridTable rowData={CgProgrammeRowData} gridOptions={gridOptions} onCellClicked={onCellClicked} />
                  )}
                </Tab>
                <Tab eventKey="SEPC" title="SEPC">
                  {SEPCRowDataLoading ? (
                    <TableSpinner />
                  ) : (
                    <AgGridTable rowData={SEPCRowData} gridOptions={gridOptions} onCellClicked={onCellClicked} />
                  )}
                </Tab>
                <Tab eventKey="LgVote" title="LG Vote">
                  {LgVoteRowDataLoading ? (
                    <TableSpinner />
                  ) : (
                    <AgGridTable rowData={LgVoteRowData} gridOptions={gridOptions} onCellClicked={onCellClicked} />
                  )}
                </Tab>
              </Tabs>
            </div>
          </Tab>
          <Tab eventKey="BudgetStatistics" title="Budget Statistics">
            <div className="row mt-10">
              <div className="col">
                <StatsWidget11 className="card-stretch card-stretch-half gutter-b" symbolShape="circle" baseColor="success" />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 order-2">
                <MixedWidget2
                  className="gutter-b card-stretch"
                  chartColor="danger"
                  tabSelected={mainKey === mainTabs.BudgetStatistics}
                />
              </div>
              <div className="col-md-6 order-2">
                <MixedWidget3
                  className="gutter-b card-stretch"
                  chartColor="danger"
                  tabSelected={mainKey === mainTabs.BudgetStatistics}
                />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
      <Modal show={ShowDetails} size="xl" aria-labelledby="example-modal-sizes-title-lg">
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Report Details</Modal.Title>
          <div className="tab-submit">
            <button type="button" onClick={() => setShowDetails(false)} className="btn btn-warning btn-elevate">
              <CloseIcon />
            </button>
          </div>
        </Modal.Header>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <TableCardHeader
            HTittle=""
            handleHButtonClick={populateAndDownloadTemplate}
            icon="download"
            HButtonText="Download Excel Version"
            disabled={ReportDetailsRowData && ReportDetailsRowData.length < 1}
          />
        </div>

        <Modal.Body className="overlay overlay-block cursor-default">
          <div className="mx-4">
            <Row>
              <Col md="3">
                <strong>Report Category: </strong>&nbsp;&nbsp;
                <span> {Report_Category} </span>
              </Col>
              <Col md="3">
                <strong>Report Type: </strong>&nbsp;&nbsp;
                <span> {Report_Type}</span>
              </Col>
              <Col md="6">
                <strong>Report Name: </strong>&nbsp;&nbsp;
                <span> {Report_Name}</span>
              </Col>
            </Row>
          </div>
          {ReportDetailsRowDataLoading ? (
            <TableSpinner />
          ) : (
            <AgGridTable rowData={ReportDetailsRowData} gridOptions={reportDetailsGridOptions} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
