import { gql } from "@apollo/client";

const GET_LG_IPF = gql`
  query getLgIpfs {
    lgIpfs {
      Grant_IPF_ID
      Project_Code
      SubGrant_ID {
        SubGrant_ID
        SubGrant_Name
        SubGrant_Code
      }
      Projection_Y1
      Projection_Y2
      Projection_Y3
      Projection_Y4
      Projection_Y5
      Actual_Y1Q1
      Actual_Y1Q2
      Actual_Y1Q3
      Actual_Y1Q4
      Stage
      Created_By
      Modified_By
      Programme_ID {
        Programme_ID
        Programme_Name
        Programme_Code
      }
      Vote_ID {
        Vote_ID
        Vote_Code
        Vote_Name
      }
      Revenue_ID {
        Revenue_ID
        Revenue_Name
        Revenue_Code
      }
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Sub_SubProgramme_ID {
        Sub_SubProgramme_ID
        Sub_SubProgramme_Name
        Sub_SubProgramme_Code
      }
    }
  }
`;

const GET_LG_IPF_BY_PROG_AND_VOTE = gql`
  query lgIpfByProgrammeAndVote($Programme_ID: Float!, $Vote_ID: Float!, $Stage: Float!, $Year_ID: Float!) {
    lgIpfByProgrammeAndVote(Programme_ID: $Programme_ID, Vote_ID: $Vote_ID, Stage: $Stage, Year_ID: $Year_ID) {
      Grant_IPF_ID
      Project_Code
      SubGrant_ID
      Projection_Y1
      Projection_Y2
      Projection_Y3
      Projection_Y4
      Projection_Y5
      Actual_Y1Q1
      Actual_Y1Q2
      Actual_Y1Q3
      Actual_Y1Q4
      Stage
      Created_Date
      Modified_Date
      Created_By
      Modified_By
      Programme_ID {
        Programme_ID
        Programme_Name
        Programme_Code
      }
      Vote_ID {
        Vote_ID
        Vote_Code
        Vote_Name
      }
      Revenue_ID {
        Revenue_ID
        Revenue_Name
      }
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Sub_SubProgramme_ID {
        Sub_SubProgramme_ID
        Sub_SubProgramme_Name
      }
    }
  }
`;

const GET_LG_IPF_SUBSUBPROG_BY_VOTE = gql`
  query lgIpfSubSubProgrammeByVote($Vote_ID: Float!,$Year_ID: Float!) {
    lgIpfSubSubProgrammeByVote(
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID) 
    {
      Sub_SubProgramme_ID {
        Sub_SubProgramme_ID
        Sub_SubProgramme_Code
        Sub_SubProgramme_Name
      }
    }
  }
`;

const GET_LG_IPF_REVENUE_BY_SUBSUBPROG_AND_VOTE = gql`
  query lgIpfRevenuBySubSubProgrammeAndVote($Sub_SubProgramme_ID: Float!,$Vote_ID: Float!,$Year_ID: Float!) {
    lgIpfRevenuBySubSubProgrammeAndVote(
    Sub_SubProgramme_ID: $Sub_SubProgramme_ID,
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID) 
    {
      Revenue_ID {
        Revenue_ID
        Revenue_Code
        Revenue_Name
      }
    }
  }
`;

const GET_LG_IPF_SUBGRANT_BY_REVENUE_AND_VOTE = gql`
  query lgIpfSubGrantByRevenueAndVote($Revenue_ID: Float!,$Vote_ID: Float!,$Year_ID: Float!) {
    lgIpfSubGrantByRevenueAndVote(
    Revenue_ID: $Revenue_ID,
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID) 
    {
      SubGrant_ID {
        SubGrant_ID
        SubGrant_Name
        SubGrant_Code
      }
    }
  }
`;

const GET_LG_IPF_DETAILS_CGT_BY_VOTE = gql`
  query lgIpfDetailsCGTByVote($Vote_ID: Float!,$Year_ID: Float!, $Stage: Float!) {
    lgIpfDetailsCGTByVote(
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID,
    Stage: $Stage) 
    {
      Grant_IPF_ID
      Projection_Y1
      Projection_Y2
      Projection_Y3
      Projection_Y4
      Projection_Y5
      Stage
      Created_By
      Modified_By
      Vote_ID {
        Vote_ID
        Vote_Code
        Vote_Name
      }
      Revenue_ID {
        Revenue_ID
        Revenue_Name
        Revenue_Code
      }
      SubGrant_ID {
        SubGrant_ID
        SubGrant_Name
        SubGrant_Code
      }
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Sub_SubProgramme_ID {
        Sub_SubProgramme_ID
        Sub_SubProgramme_Name
        Sub_SubProgramme_Code
      }
    }
  }
`;

const GET_LG_CONDITIONAL_BY_VOTE = gql`
  query lgGetConditionalTransfersGrid($Vote_ID: Float!,$Stage: Float!) {
    lgGetConditionalTransfersGrid(
    Vote_ID: $Vote_ID,
    Stage: $Stage) 
    {
      Year_ID,
      Vote_ID,
      Sub_SubProgramme_ID,
      Revenue_ID,
      Projection_Y1,
      Projection_Y2,
      Projection_Y3,
      Projection_Y4,
      Projection_Y5,
      Stage,
      Revenue_Budget,
      Revenue_Code,
      Revenue_Name,
      Sub_SubProgramme_Code
      Sub_SubProgramme_Name,
      Vote_Code,
      Vote_Name,
      Projection_Y1Q1,
      Projection_Y1Q2,
      Projection_Y1Q3,
      Projection_Y1Q4,
      Group_Name,
      Plan_Y0 ,
      ID
    }
  }
`;

const GET_LG_DISCRETIONARY_BY_VOTE = gql`
  query lgGetDiscretionaryTransfersGrid($Vote_ID: Float!,$Stage: Float!) {
    lgGetDiscretionaryTransfersGrid(
    Vote_ID: $Vote_ID,
    Stage: $Stage) 
    {
      Year_ID,
      Vote_ID,
      Sub_SubProgramme_ID,
      Revenue_ID,
      Projection_Y1,
      Projection_Y2,
      Projection_Y3,
      Projection_Y4,
      Projection_Y5,
      Stage,
      Revenue_Budget,
      Revenue_Code,
      Revenue_Name,
      Sub_SubProgramme_Code
      Sub_SubProgramme_Name,
      Vote_Code,
      Vote_Name,
      Projection_Y1Q1,
      Projection_Y1Q2,
      Projection_Y1Q3,
      Projection_Y1Q4,
      Group_Name,
      Plan_Y0 ,
      ID,
      SubGrant_Name,
      SubGrant_Code,
      SubGrant_ID
    }
  }
`;

const GET_BUDGET_GRANT_IPF = gql`
  query getBudgetGrantIpf {
    budgetGrantIpf {
      Projection_Y1
      Projection_Y2
      Projection_Y3
      Projection_Y4
      Projection_Y5
    }
  }
`;

const GET_LG_IPF_GRID_DETAILS_BY_YEAR_AND_STAGE = gql`
  query lgIpfGridDetailsByYearAndStage($Year_ID: Float!,$Stage: Float!) {
    lgIpfGridDetailsByYearAndStage( Year_ID:$Year_ID, Stage:$Stage) {
      Grant_IPF_ID
      Project_Code
      SubGrant_ID {
        SubGrant_ID
        SubGrant_Name
        SubGrant_Code
      }
      Projection_Y1
      Projection_Y2
      Projection_Y3
      Projection_Y4
      Projection_Y5
      Actual_Y1Q1
      Actual_Y1Q2
      Actual_Y1Q3
      Actual_Y1Q4
      Stage
      Created_By
      Modified_By
      Programme_ID {
        Programme_ID
        Programme_Name
        Programme_Code
      }
      Vote_ID {
        Vote_ID
        Vote_Code
        Vote_Name
      }
      Revenue_ID {
        Revenue_ID
        Revenue_Name
        Revenue_Code
      }
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Sub_SubProgramme_ID {
        Sub_SubProgramme_ID
        Sub_SubProgramme_Name
        Sub_SubProgramme_Code
      }
    }
  }
`;

const GET_LG_CONDITIONAL_BY_VOTE_LOAD_PREVIOUS_DATA = gql`
  query lgGetConditionalTransfersLoadPreviousData($Vote_ID: Float!,$Year_ID: Float!,$Stage: Float!) {
    lgGetConditionalTransfersLoadPreviousData(
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID,
    Stage: $Stage) 
    {
      Created_By
      Modified_By
    }
  }
`;

const GET_LG_DISCRETIONARY_BY_VOTE_LOAD_PREVIOUS_DATA = gql`
  query lgGetDiscretionaryTransfersLoadPreviousData($Vote_ID: Float!, $Year_ID: Float!, $Stage: Float!) {
    lgGetDiscretionaryTransfersLoadPreviousData(
    Vote_ID: $Vote_ID,
    Year_ID: $Year_ID,
    Stage: $Stage) 
    {
      Created_By
      Modified_By
    }
  }
`;

export { 
  GET_LG_IPF,
  GET_LG_IPF_BY_PROG_AND_VOTE,
  GET_LG_IPF_SUBSUBPROG_BY_VOTE,
  GET_LG_IPF_REVENUE_BY_SUBSUBPROG_AND_VOTE,
  GET_LG_IPF_SUBGRANT_BY_REVENUE_AND_VOTE,
  GET_LG_IPF_DETAILS_CGT_BY_VOTE,
  GET_LG_CONDITIONAL_BY_VOTE ,
  GET_LG_DISCRETIONARY_BY_VOTE,
  GET_BUDGET_GRANT_IPF,
  GET_LG_IPF_GRID_DETAILS_BY_YEAR_AND_STAGE,
  GET_LG_CONDITIONAL_BY_VOTE_LOAD_PREVIOUS_DATA,
  GET_LG_DISCRETIONARY_BY_VOTE_LOAD_PREVIOUS_DATA
};
