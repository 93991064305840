/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { checkIsActive } from "../../../../_helpers";
import { SideMenuPopup } from "./SideMenuPopup";

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();

    const { userMenu } = useSelector(({ auth }) => ({ userMenu: auth.menu }), shallowEqual);

    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    };

    const [menu, setMenu] = useState(false);
    const [menuData, setMenuData] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        setMenuData(userMenu);
    }, [userMenu]);

    const toggleSideMenu = (menu, isMenuOpen) => (event) => {
        event.preventDefault();
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }

        setMenu(menu);
        setIsMenuOpen(isMenuOpen);
    };

    return (
        <div
            id="kt_header_menu"
            className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
            {...layoutProps.headerMenuAttributes}
        >
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/dashboard")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/dashboard">
                        <img src={toAbsoluteUrl("../../../media/icons/home.png")} alt="Dashboard" />
                        <div className="menu-text ml-1">Dashboard</div>
                        {/* {layoutProps.rootArrowEnabled && <i className="menu-arrow" />} */}
                    </NavLink>
                </li>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/admin")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/admin" onClick={toggleSideMenu("Admin", true)}>
                        <img src={toAbsoluteUrl("../../../media/icons/admin.png")} alt="Administration" />
                        <span className="menu-text">Admin</span>
                        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                    </NavLink>
                </li>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/masters")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/masters" onClick={toggleSideMenu("Masters", true)}>
                        <img src={toAbsoluteUrl("../../../media/icons/master.png")} alt="Masters" />
                        <span className="menu-text">Masters</span>
                        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                    </NavLink>
                </li>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/budget")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/budget">
                        <img src={toAbsoluteUrl("../../../media/icons/budget.png")} alt="Budget" />
                        <span className="menu-text">Budget</span>
                        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                    </NavLink>
                </li>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/reports")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/reports" onClick={toggleSideMenu("Reports", true)}>
                        <img src={toAbsoluteUrl("../../../media/icons/reports.png")} alt="Reports" />
                        <span className="menu-text">Reports</span>
                        {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                    </NavLink>
                </li>
                <li className={`menu-item menu-item-rel ${getMenuItemActive("/archives")}`}>
                    <NavLink className="menu-link d-md-block text-center" to="/archives">
                        <img src={toAbsoluteUrl("../../../media/icons/archive.png")} alt="Archives" />
                        <span className="menu-text">Archives</span>
                        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
                    </NavLink>
                </li>
            </ul>

            <SideMenuPopup menu={menu} isMenuOpen={isMenuOpen} toggleMenu={toggleSideMenu} menuData={menuData} />
        </div>
    );
}
