import { gql } from "@apollo/client";

const GET_LOGINS = gql`
  query getLogins {
    query {
      logins {
        User_Name
        Ip_Address
        Login_Date
        Login_Status
        Login_Message
        Created_Date
        Created_By
      }
    }
  }
`;

const GET_LOGINS_IN_DATE_RANGE = gql`
  query getLoginsInDateRange($StartDate: DateTime!, $EndDate: DateTime!, $Offset: Float!, $Limit: Float!) {
    loginsInDateRange(StartDate: $StartDate, EndDate: $EndDate, Offset: $Offset, Limit: $Limit) {
      dataList {
        _id
        User_Name
        Ip_Address
        Login_Date
        Login_Status
        Login_Message
        Created_Date
        Created_By
      }
      availableDataSize
    }
  }
`;

const GET_FAILED_LOGINS = gql`
  query getFailedLogins {
    failedLogins {
      _id
      User_Name
      Ip_Address
      Login_Date
      Login_Status
      Login_Message
      Created_Date
      Created_By
    }
  }
`;

const GET_FAILED_LOGINS_IN_DATE_RANGE = gql`
  query getFailedLoginsInDateRange($StartDate: DateTime!, $EndDate: DateTime!, $Offset: Float!, $Limit: Float!) {
    failedLoginsInDateRange(StartDate: $StartDate, EndDate: $EndDate, Offset: $Offset, Limit: $Limit) {
      dataList {
        _id
        User_Name
        Ip_Address
        Login_Date
        Login_Status
        Login_Message
        Created_Date
        Created_By
      }
      availableDataSize
    }
  }
`;

export { GET_LOGINS, GET_LOGINS_IN_DATE_RANGE, GET_FAILED_LOGINS, GET_FAILED_LOGINS_IN_DATE_RANGE };
