import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  from,
  fromPromise,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { onError } from "@apollo/client/link/error";
// import { WebSocketLink } from "@apollo/client/link/ws";
// import { getMainDefinition } from "@apollo/client/utilities";
// import { SubscriptionClient } from "subscriptions-transport-ws";
import { actions as authActions } from "../app/Modules/Auth/_redux/authRedux";
import { REFRESH_TOKENS_MUTATION } from "../app/Modules/Administration/_Commons/Constants/Queries/Auth";

 const getwayHttpLink = new HttpLink({ uri: "https://pbsgateway.finance.go.ug/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://pbsuat.finance.go.ug:8000/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://154.72.192.26:8044/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://localhost:8000/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://10.224.4.60:8000/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://192.168.5.36:8000/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "http://192.168.5.42:8000/graphql" });
// const getwayHttpLink = new HttpLink({ uri: "https://pbsgateway2.finance.go.ug/graphql" });

//const auditLink = "http://154.72.192.26:8071/graphql";
// const auditLink = "http://localhost:8001/graphql";
const auditLink = "https://pbsapi.finance.go.ug/graphql";
// const auditLink = "https://pbsapi2.finance.go.ug/graphql";
// const auditLink = "http://pbsuat.finance.go.ug:8001/graphql";

export const fileUploadLink = "https://pbsapi.finance.go.ug";
// export const fileUploadLink = "http://localhost:8001";
// export const fileUploadLink = "https://pbsapi2.finance.go.ug";
//export const fileUploadLink = "http://pbsuat.finance.go.ug:8000";

export const reportsGeneratorHttpLink = new HttpLink({ uri: auditLink });

export const signatureUploadDirectory = "ElectronicSignatures";
export const reportsUploadDirectory = "ReportsUpload";
export const cgPenssionListUploadDirectory = "CgPenssionListUpload";
export const lgPenssionListUploadDirectory = "LgPenssionListUpload";
export const cgStaffListUploadDirectory = "CgStaffListUpload";
export const lgStaffListUploadDirectory = "LgStaffListUpload";
export const lgStudentListUploadDirectory = "LgStudentListUpload";
export const cgLrrExcelUploadDirectory = "CgLrrExcelUpload";
export const cgWarrantsandReleasesUploadDirectory = "CgWarrantsandReleasesUpload";
export const cgExternalFinanceEstimatesExcelUploadDirectory = "CgExternalFinanceEstimatesExcelUpload";
export const cgQuarterlyCashLimitsExcelUploadDirectory = "CgQuarterlyCashLimitsExcelUpload";
export const cgSupplementaryAllocationUploadDirectory = "CgSupplementaryAllocationUpload";
export const lgSupplementaryAllocationUploadDirectory = "LgSupplementaryAllocationUpload";
export const cgVirementAllocationUploadDirectory = "CgVirementAllocationUpload";
export const lgVirementAllocationUploadDirectory = "LgVirementAllocationUpload";
export const cgMtefUploadDirectory = "CgMtefUpload";
export const lgIpfUploadDirectory = "LgIpfUpload";

export const cgGoUExpLimitsUploadDirectory = "CgGoUExpLimitsUpload";
export const cgLrrExpLimitsUploadDirectory = "CgLrrExpLimitsUpload";
export const cgExtFinExpLimitsUploadDirectory = "CgExtFinExpLimitsUpload";
export const lgGoUExpLimitsUploadDirectory = "LgGoUExpLimitsUpload";
export const lgLrrExpLimitsUploadDirectory = "LgGoUExpLimitsUpload";
export const lgExtFinExpLimitsUploadDirectory = "LgGoUExpLimitsUpload";
export const lgQuarterlyReleasesUploadDirectory = "LgQuarterlyReleasesUpload";
export const lgQuarterlyWarrantsAndExpenditureUploadDirectory = "LgQuarterlyWarrantsAndExpenditureUpload";
export const cgQuarterlyRelAndExpDeptProjUploadDirectory = "CgQuarterlyRelAndExpDeptProjUpload";
export const lgQuarterlyWarrantsAndExpenditureLlgUploadDirectory = "LgQuarterlyWarrantsAndExpenditureLlgUpload";

export const reportGeneratorDirectory = "ReportGenerator";

export const signatureUploadLink = `${fileUploadLink}/${signatureUploadDirectory}`;
export const reportsUploadLink = `${fileUploadLink}/${reportsUploadDirectory}`;
export const cgPenssionListUploadLink = `${fileUploadLink}/${cgPenssionListUploadDirectory}`;
export const lgPenssionListUploadLink = `${fileUploadLink}/${lgPenssionListUploadDirectory}`;
export const cgStaffListUploadLink = `${fileUploadLink}/${cgStaffListUploadDirectory}`;
export const lgStaffListUploadLink = `${fileUploadLink}/${lgStaffListUploadDirectory}`;
export const lgStudentListUploadLink = `${fileUploadLink}/${lgStudentListUploadDirectory}`;
export const cgLrrExcelUploadLink = `${fileUploadLink}/${cgLrrExcelUploadDirectory}`;
export const cgWarrantsandReleasesUploadLink = `${fileUploadLink}/${cgWarrantsandReleasesUploadDirectory}`;
export const cgExternalFinanceEstimatesExcelUploadLink = `${fileUploadLink}/${cgExternalFinanceEstimatesExcelUploadDirectory}`;
export const cgQuarterlyCashLimitsExcelUploadLink = `${fileUploadLink}/${cgQuarterlyCashLimitsExcelUploadDirectory}`;
export const cgSupplementaryAllocationUploadLink = `${fileUploadLink}/${cgSupplementaryAllocationUploadDirectory}`;
export const lgSupplementaryAllocationUploadLink = `${fileUploadLink}/${lgSupplementaryAllocationUploadDirectory}`;
export const cgVirementAllocationUploadLink = `${fileUploadLink}/${cgVirementAllocationUploadDirectory}`;
export const lgVirementAllocationUploadLink = `${fileUploadLink}/${lgVirementAllocationUploadDirectory}`;
export const cgMtefUploadLink = `${fileUploadLink}/${cgMtefUploadDirectory}`;
export const lgIpfUploadLink = `${fileUploadLink}/${lgIpfUploadDirectory}`;

export const cgGoUExpLimitsUploadLink = `${fileUploadLink}/${cgGoUExpLimitsUploadDirectory}`;
export const cgLrrExpLimitsUploadLink = `${fileUploadLink}/${cgLrrExpLimitsUploadDirectory}`;
export const cgExtFinExpLimitsUploadLink = `${fileUploadLink}/${cgExtFinExpLimitsUploadDirectory}`;

export const lgGoUExpLimitsUploadLink = `${fileUploadLink}/${lgGoUExpLimitsUploadDirectory}`;
export const lgLrrExpLimitsUploadLink = `${fileUploadLink}/${lgLrrExpLimitsUploadDirectory}`;
export const lgExtFinExpLimitsUploadLink = `${fileUploadLink}/${lgExtFinExpLimitsUploadDirectory}`;

export const lgQuarterlyReleasesUploadLink = `${fileUploadLink}/${lgQuarterlyReleasesUploadDirectory}`;
export const lgQuarterlyWarrantsAndExpenditureUploadLink = `${fileUploadLink}/${lgQuarterlyWarrantsAndExpenditureUploadDirectory}`;

export const cgQuarterlyRelAndExpDeptProjUploadLink = `${fileUploadLink}/${cgQuarterlyRelAndExpDeptProjUploadDirectory}`;
export const lgQuarterlyWarrantsAndExpenditureLlgUploadLink = `${fileUploadLink}/${lgQuarterlyWarrantsAndExpenditureLlgUploadDirectory}`;

export const  reportGeneratorUploadLink = `${fileUploadLink}/${reportGeneratorDirectory}`;

const auditHttpLink = new HttpLink({ uri: auditLink });

// const auditWsLink = new WebSocketLink(
//   new SubscriptionClient(
//     //"wss://pbsapi.finance.go.ug/graphql",
//       //'ws://pbsuat.finance.go.ug:8001/graphql',
//     // 'ws://154.72.192.26:8071/graphql',
//     "ws://localhost:8001/graphql",
//     {
//       reconnect: true,
//       // lazy: true,
//       // timeout: 30000,
//     }
//   )
// );

const getNewTokens = (client, access_token, refresh_token) => {
  return client
    .mutate({
      mutation: REFRESH_TOKENS_MUTATION,
      variables: {
        access_token: access_token,
        refresh_token: refresh_token,
      },
    })
    .then((response) => response.data.refreshToken);
};

const authorizedMiddleware = (store) => {
  return new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const {
      auth: { access_token },
    } = store.getState();

    const bearerAccessToken = access_token ? `Bearer ${access_token}` : null;

    operation.setContext({
      headers: {
        authorization: bearerAccessToken,
      },
    });

    return forward(operation);
  });
};

const unAuthorizedMiddleware = (
  store,
  client,
  graphQLErrors,
  networkError,
  operation,
  forward
) => {
  let unauthorized = false;

  if (graphQLErrors) {
    graphQLErrors.map(async ({ message }) => {
      if (message === "Unauthorized") {
        unauthorized = true;
      }

      return message;
    });
  }

  if (
    networkError &&
    (networkError.statusCode === 401 || networkError.message === "Unauthorized")
  ) {
    unauthorized = true;
  }

  if (unauthorized && !window.location.pathname.includes("auth")) {
    const {
      auth: { user, access_token, refresh_token },
    } = store.getState();

    return fromPromise(
      getNewTokens(client, access_token, refresh_token)
        .then(async (newTokens) => {
          await store.dispatch(
            authActions.refreshTokens(
              newTokens.access_token,
              newTokens.refresh_token
            )
          );
          return newTokens;
        })
        .catch(async (error) => {
          if (!user) {
            await store.dispatch(authActions.logout());
          }
          return;
        })
    )
      .filter((value) => Boolean(value))
      .flatMap((newTokens) => {
        const oldHeaders = operation.getContext().headers;

        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${newTokens.access_token}`,
          },
        });

        return forward(operation);
      });
  }
};

export default function setupApolloClient(store, customLink="") {
  const authMiddleware = authorizedMiddleware(store);

  const unAuthMiddleware = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      return unAuthorizedMiddleware(
        store,
        client,
        graphQLErrors,
        networkError,
        operation,
        forward
      );
    }
  );
  
  const httpLink = customLink || getwayHttpLink;

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authMiddleware, unAuthMiddleware, httpLink]),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        // fetchPolicy: "cache-and-network",
        fetchPolicy: "cache-first",
      },
    },
  });

  return client;
}

export const uploadClient = (store) => {
  const authMiddleware = authorizedMiddleware(store);

  const unAuthMiddleware = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      return unAuthorizedMiddleware(
        store,
        client,
        graphQLErrors,
        networkError,
        operation,
        forward
      );
    }
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([
      authMiddleware,
      unAuthMiddleware,
      createUploadLink({ uri: auditLink }),
    ]),
  });

  return client;
};

export const notificationClient = (store) => {
  // const notificationLink = split(
  //   // split based on operation type
  //   ({ query }) => {
  //     const definition = getMainDefinition(query);
  //     return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  //   },
  //   auditWsLink,
  //   auditHttpLink
  // );

  const authMiddleware = authorizedMiddleware(store);

  const unAuthMiddleware = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      return unAuthorizedMiddleware(
        store,
        client,
        graphQLErrors,
        networkError,
        operation,
        forward
      );
    }
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([authMiddleware, unAuthMiddleware, auditHttpLink]),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        // fetchPolicy: "cache-and-network",
        fetchPolicy: "cache-first",
      },
    },
  });

  return client;
};
