import { useQuery } from "@apollo/client";
import ApexCharts from "apexcharts";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import store from "../../../../redux/store";
import { notificationClient } from "../../../../redux/setupApolloClient";
import { GET_AUDITS_IN_DATE_RANGE } from "../../../../app/Modules/Administration/_Commons/Constants/Queries/audits";
import TopRecordSelect from "../../../../app/Modules/Helpers/TopRecordSelect";

export function AdvanceTablesWidget8({ className, Start_Date, End_Date }) {
  const [create, setCreate] = useState([]);
  const [update, setUpdate] = useState([]);
  const [remove, setRemove] = useState([]);
  const [client, setClient] = useState(null);

  const [AuditsLimit, setAuditsLimit] = useState(1000);

  useEffect(() => setClient(notificationClient(store)), []);

  // const handleGetAudits = useQuery(GET_AUDITS, { client });

  const handleGetAudits = useQuery(GET_AUDITS_IN_DATE_RANGE, {
    client,
    variables: {
      StartDate: Start_Date,
      EndDate: End_Date,
      Offset: 0,
      Limit: Number(AuditsLimit)
    },
    skip: !(client && Start_Date && End_Date && (End_Date >= Start_Date)),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetAudits;

    if (error) toastr.error("Failed", error.message);
    if (!loading && data && data.auditsInDateRange && data.auditsInDateRange.dataList) {
      const auditLogs = data.auditsInDateRange.dataList;
      const createLogs = auditLogs.filter((log) => log.Audit_Type === "create");
      const updateLogs = auditLogs.filter((log) => log.Audit_Type === "update");
      const removeLogs = auditLogs.filter((log) => log.Audit_Type === "remove");

      const monthsData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
      };

      let sortedCreateLogs = monthsData;
      let sortedUpdateLogs = monthsData;
      let sortedDeleteLogs = monthsData;

      createLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedCreateLogs[createdDateMonth] =
          createdDateMonth && sortedCreateLogs[createdDateMonth] ? parseInt(sortedCreateLogs[createdDateMonth]) + 1 : 1;
      });
      sortedCreateLogs = sortedCreateLogs && Object.keys(sortedCreateLogs).map((log) => sortedCreateLogs[log]);

      updateLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedUpdateLogs[createdDateMonth] =
          createdDateMonth && sortedUpdateLogs[createdDateMonth] ? parseInt(sortedUpdateLogs[createdDateMonth]) + 1 : 1;
      });
      sortedUpdateLogs = sortedUpdateLogs && Object.keys(sortedUpdateLogs).map((log) => sortedUpdateLogs[log]);

      removeLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedDeleteLogs[createdDateMonth] =
          createdDateMonth && sortedDeleteLogs[createdDateMonth] ? parseInt(sortedDeleteLogs[createdDateMonth]) + 1 : 1;
      });
      sortedDeleteLogs = sortedDeleteLogs && Object.keys(sortedDeleteLogs).map((log) => sortedDeleteLogs[log]);

      setCreate(sortedCreateLogs);
      setUpdate(sortedUpdateLogs);
      setRemove(sortedDeleteLogs);
    }
  }, [handleGetAudits, Start_Date, End_Date]);

  useEffect(() => {
    const element = document.getElementById("kt_advanced_table_widget_8_chart");

    if (!element) {
      return;
    }

    let options = getChartOptions();

    options.series = [
      {
        name: "Creates",
        type: "column",
        data: create,
      },
      {
        name: "Updates",
        type: "area",
        data: update,
      },
      {
        name: "Deletes",
        type: "line",
        data: remove,
      },
    ];

    options.categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const chart = new ApexCharts(element, options);

    chart.render();

    return function cleanUp() {
      chart.destroy();
    };
  }, [create, update, remove]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Audits graph</span>
        </h3>
        <TopRecordSelect numberOfRecords={AuditsLimit} changeNumberOfRecords={(e) => setAuditsLimit(e.target.value)} />
      </div>
      <div className="card-body pt-3 pb-0 mx-5">
        <div className="col-12 p-3 text-center">
          <h3 className="text-info mb-5">Audit Logs by category</h3>
          <div id="kt_advanced_table_widget_8_chart" className="w-100"></div>
        </div>
      </div>
    </div>
  );
}

function getChartOptions() {
  const colors = ["#FFA800", "#660066"];
  const options = {
    series: [],
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    stroke: {
      width: [0, 2, 5],
      curve: "smooth",
      colors: colors,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [],
    },

    markers: {
      size: 0,
    },
    yaxis: {
      title: {
        text: "Points",
      },
      min: 0,
    },

    tooltip: {
      y: [
        {
          title: {
            formatter: function(val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function(val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function(val) {
              return val;
            },
          },
        },
      ],
    },
  };

  return options;
}
