import React, { useState } from "react";
import { saveSession } from 'react-session-persist';
import { useSelector, shallowEqual } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import publicIp from "public-ip";
import * as auth from "../_redux/authRedux";
import { LOGIN_MUTATION, VERIFY_OTP_CODE_MUTATION } from "../../Administration/_Commons/Constants/Queries/Auth";
import { useScreenLock } from "../../../screenLock";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  username: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const screenLock = useScreenLock();
  const { otpVerification } = useSelector((state) => state.auth, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [userLogin] = useMutation(LOGIN_MUTATION);
  const [verifyOtpCode] = useMutation(VERIFY_OTP_CODE_MUTATION);

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      // .email("Wrong email format")
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.USER_NAME",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 characters")
      .max(50, "Maximum 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.PASSWORD",
        })
      ),
  });

  const OtpCodeSchema = Yup.object().shape({
    code: Yup.string()
      .min(5, "Code should be 5 digits")
      .max(5, "Code should be 5 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (
      (loginFormik.touched[fieldname] && loginFormik.errors[fieldname]) ||
      (otpCodeFormik.touched[fieldname] && otpCodeFormik.errors[fieldname])
    ) {
      return "is-invalid";
    }

    if (
      (loginFormik.touched[fieldname] && !loginFormik.errors[fieldname]) ||
      (otpCodeFormik.touched[fieldname] && !otpCodeFormik.errors[fieldname])
    ) {
      return "is-valid";
    }

    return "";
  };

  const processLogin = async (loginDetails) => {
    const { 
      access_token, refresh_token, 
      user, userMenu, activeFiscalYear, 
      // previousFiscalYear, stages, 
      activeStages, userRole, userVote 
    } = loginDetails;

    let mainMenus = userMenu && userMenu.map(menu => menu.Main_Menu_ID);
    let mainMenuIds = userMenu && userMenu.map(menu => menu.Main_Menu_ID && menu.Main_Menu_ID.Main_Menu_ID);
    let menuPaths = ['/', '/dashboard', '/budget', '/archives', '/user-profile', '/user-profile/personal-information', '/user-profile/change-password', '/logout'];

    mainMenuIds = [...new Set(mainMenuIds)];

    const menuData = mainMenuIds.map(mainMenuId => {
        const mainMenu = mainMenus.find(mainMenu => mainMenu.Main_Menu_ID === mainMenuId);
        const mainMenuSubMenus = userMenu.filter(menuData => menuData.Main_Menu_ID && menuData.Main_Menu_ID.Main_Menu_ID === mainMenuId);
        const sortedMainMenuSubMenus = mainMenuSubMenus.sort((a, b) => a.Sub_Menu_Name.trim().localeCompare(b.Sub_Menu_Name.trim()));
        return {
            ...mainMenu,
            subMenus: sortedMainMenuSubMenus,
        }
    });

    const cleanedMenuData = menuData.map(menu => {
      if(menu && menu.Process_Area && menu.Process_Area.length > 0 && menu.Process_Area_Category && menu.Process_Area_Category.length > 0) {
        const processArea = menu.Process_Area.split(",").map(processArea => processArea.trim().toLowerCase());
        const subMenus = processArea.map(processArea => menu.subMenus.map(subMenu => ({ ...subMenu, Sub_Menu_Path: subMenu.Sub_Menu_Path.replace(/process-area/g, processArea) })));
        const cleanedSubMenus = [].concat.apply([], subMenus);
        const cleanedSubMenusPaths = cleanedSubMenus.map(subMenu => subMenu.Sub_Menu_Path);
        menuPaths = [...menuPaths, ...cleanedSubMenusPaths];
        return { ...menu, subMenus: cleanedSubMenus };
      } else {
        const subMenus = menu.subMenus.map(subMenu => subMenu.Sub_Menu_Path);
        menuPaths = [...menuPaths, ...subMenus];
        return menu;
      }
    });

    const sortedMenuData = cleanedMenuData.sort((a, b) => a.Main_Menu_Name.trim().localeCompare(b.Main_Menu_Name.trim()));
    
    props.createOtpVerification();
    screenLock.setIsLocked(false);
    
    await saveSession({"suppression": false});
    
    if (sortedMenuData) props.createMenu(sortedMenuData);
    if (menuPaths) props.createMenuPaths(menuPaths);    
    if (access_token && refresh_token) props.login(access_token, refresh_token);
    if (user) props.setUser(user);
    if (activeFiscalYear) props.createActiveFiscalYear(activeFiscalYear);
    // if (previousFiscalYear) props.createPreviousFiscalYear(previousFiscalYear);
    // if (stages) props.createStages(stages);
    if (activeStages) props.createActiveStages(activeStages);
    if (userRole) props.createUserRole(userRole);
    if (userVote) props.createUserVote(userVote);
  }

  const loginFormik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoading();
      userLogin({ variables: { ipAddress: await publicIp.v4(), username: values.username.trim(), password: values.password.trim() } })
        .then(async ({ data : { login }}) => {
          disableLoading();
          setSubmitting(false);

          if (login && login.otpVerification) {
            const loginUser = login && login.user;
            const loginUserPhoneNumber = loginUser && loginUser.Mobile_Number;
            const encryptedLoginUserPhoneNumber = `+256 XXX XXX X${loginUserPhoneNumber && loginUserPhoneNumber.slice(-2)}`;

            props.createOtpVerification({
              required: true,
              loginDetails: login,
              userPhoneNumber: encryptedLoginUserPhoneNumber,
            });
          } else {
            await processLogin(login);
          }
        })
        .catch((error) => {
          disableLoading();
          setSubmitting(false);

          const extractedError = JSON.parse(JSON.stringify(error, null, 2));
          const errorMessage = extractedError && extractedError.message;

          if (errorMessage && errorMessage.trim()) {
            setStatus(
              intl.formatMessage({
                id: errorMessage,
              })
            );
          } else {
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          }
        });
    },
  });

  const otpCodeFormik = useFormik({
    initialValues,
    validationSchema: OtpCodeSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      verifyOtpCode({ variables: { code: values.code.toString() } })
        .then(async ({ data: { verifyOtpCode: { valid, used, userId }} }) => {
          disableLoading();
          setSubmitting(false);

          if (!otpVerification) {
            props.createOtpVerification();
          } else {
            if (valid && !used && userId === otpVerification.loginDetails.user.User_ID) {
              const { loginDetails } = otpVerification;              
              await processLogin(loginDetails);              
            } else if (valid && !used) {
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.USED_OTP_CODE" },
                  { code: values.code }
                )
              );
            } else {
              setStatus(
                intl.formatMessage(
                  { id: "AUTH.VALIDATION.INVALID_OTP_CODE" },
                  { code: values.code }
                )
              );
            }
          }
        }).catch((error) => {
          disableLoading();
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.INVALID_OTP_CODE" },
              { code: values.code }
            )
          );
        });
    },
  });

  return (
    <>
    { (otpVerification && otpVerification.required && otpVerification.userPhoneNumber) ? (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">Enter Code</h3>
            <div className="text-muted font-weight-bold">
              We texted the code to your phone <span className="text-dark">{otpVerification.userPhoneNumber}</span>.
              Please enter the code to sign in.
            </div>
          </div>
          <form
            onSubmit={otpCodeFormik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {otpCodeFormik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {otpCodeFormik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="number"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "code"
                )}`}
                name="code"
                placeholder="Code"
                {...otpCodeFormik.getFieldProps("code")}
              />
              {otpCodeFormik.touched.code && otpCodeFormik.errors.code ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block text-danger">{otpCodeFormik.errors.code}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-outline-dark font-weight-bold px-9 py-4 my-3 mx-4"
                onClick={() => props.createOtpVerification()}
              >
                Cancel
              </button>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={otpCodeFormik.isSubmitting}
              >
                <span>Verify</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.LOGIN.TITLE" />
            </h3>
            <p className="text-muted font-weight-bold">
              Enter your username and password
            </p>
          </div>
          {/* end::Head */}

          {/*begin::Form*/}
          <form
            onSubmit={loginFormik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework"
            autoComplete="off"
          >
            {loginFormik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">{ReactHtmlParser(loginFormik.status)}</div>
              </div>
            ) : (
              <div>
                {/*
                <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
                  <div className="alert-text ">
                    Use account <strong>admin@demo.com</strong> and password{" "}
                    <strong>demo</strong> to continue.
                  </div>
                </div>
                */}
              </div>
            )}

            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Username"
                type="text"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "username"
                )}`}
                name="username"
                {...loginFormik.getFieldProps("username")}
              />
              {loginFormik.touched.username && loginFormik.errors.username ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{loginFormik.errors.username}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder="Password"
                type="password"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...loginFormik.getFieldProps("password")}
              />
              {loginFormik.touched.password && loginFormik.errors.password ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{loginFormik.errors.password}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to="/auth/forgot-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="kt_login_forgot"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>
              <button
                id="kt_login_signin_submit"
                type="submit"
                disabled={loginFormik.isSubmitting}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                <span>Sign In</span>
                {loading && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
          {/*end::Form*/}
        </div>
      )
    }
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));