/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import ReduxToastr from 'react-redux-toastr'
import Session from 'react-session-persist';
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";

import { LicenseManager } from "ag-grid-enterprise";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
// import "@grapecity/activereports/pdfexport";
// import "@grapecity/activereports/htmlexport";
// import "@grapecity/activereports/xlsxexport";

import { Core } from "@grapecity/activereports";

// import "./Modules/Reports/_Commons/theme/ar-js-designer.css";
import "./Modules/Reports/_Commons/theme/ar-js-ui.css";
import { ScreenLockProvider } from "./screenLock";
import { NotificationProvider } from "./Notification";
// import "./Modules/Reports/_Commons/theme/ar-js-viewer.css";

// LicenseManager.setLicenseKey("CompanyName=Techno Brain Group,LicensedApplication=TechnoBrain Group,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-018058,ExpiryDate=13_August_2022_[v2]_MTY2MDM0NTIwMDAwMA==55aa7c122a232c78b9f2c32b313d0b2d");

LicenseManager.setLicenseKey("CompanyName=Techno Brain Group,LicensedApplication=TechnoBrain Group,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-030517,SupportServicesEnd=13_August_2023_[v2]_MTY5MTg4MTIwMDAwMA==c451863b10a693d2f897463c6ec20148");

//Core.setLicenseKey("pbsuat.finance.go.ug,954667911227497#B0g19ROxUY9NXaXdXY4F5ViRldKh4dFFEcSlER9UVUYdXU4BDSu96SYNEOrtSdGZkURh7am3mVqVXaHZXRHZUbiNDNQxEU7oFeKJnVSlDWJRjbzFVNB3WVLFFR9c4LxNmSJ94dxoHbHNDdhl6MFpkZXpUYvE4U6U7RDlXSqV5QGp4R6Y5LwEUTHNHbHFGZxtiZGF6Tyx4U4YHWplTbDJTeqtWNwombyhkNqFnRPhVSvkzSORWVjtWRuhjYUhmQtx4ZWx4MD5kTr9mMrs4SrV4dLhUZU3GM6gTev8mTyETSDxUSp9EazQTdmFWMzQUZUxmWrkTNzY7aiojITJCLiQ4MxETNClTNiojIIJCLxIjNzUDN6QjN0IicfJye35XX3JSSWFURiojIDJCLiIjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiI4ADOwkDMggTMwETMyAjMiojI4J7QiwiInVnLvdmLlNmbh9Wam9CdhV7ciBnI0IyctRkIsICc53mcHBibpFmcC3mboNWZUJiOiEmTDJCLicTO4cjMyETM9cjN6QTN9IiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TP7FVVVlnYm3EWYNlNMVFarskRM5UNSlFM4YTQ5kzdUVEUhdFZ4Y4YnBXS0tkW4Z5cCJ4aQJUdPNXcwh4cW3SRVlnMSVWeUxWekBlQX9kMBF7TUBXY5VXd4kmSLh6cuRiR");

Core.setLicenseKey("pbsmof.finance.go.ug,954667911227497#B0L5tzThtWYnN7ZqFWWN5EbIdHOUNFWjdDNuZWU5JzUWVDdXdGTJ3iaFllV0h5R4F4cMl5MKR6KFZUYoBXau9EWmZ7bpFkUvQ5d6Y7SGNjVINWerRFVmRWZuJ5aoVTWuF7L7VlUVVVYohXVSVzKFdFbIl6TllHapVVMGtEU0BFOYJmRCVHO6d6UMJ4RPpVOJV6Y8gVVmBTOZNlSvQFRUdjWVZmMHR4VkFGeVhEcZ9EZJ9UTIljSOR6RxRXUKZ7NFZDeKd5dqJ7ZkdVaCZmUyUEb4syVZxUQz9WQUFDZOhkNnJleGNmavhlbX96KzgFMOdnS8YVdudlI0IyUiwiIzUjQ6MTQ9MjI0ICSiwSM4ITNyYDO5MTM0IicfJye&Qf35VfikkVBVkI0IyQiwiIyYFITpEdy3GclJVZ6lGdjFkI0IiTis7W0ICZyBlIsISNxMDN8ADI6IDMxEjMwIjI0ICdyNkIsIyZ59ybn9SZj9WYulmZuY6btNnYwJiOiMXbEJCLiAXdvJ7Rg8WahJnQv9GajVGViojIh94QiwiI7kDN7IjMxETO7YjN4UTOiojIklkIs4XXbpjInxmZiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zZLljQwl4Twc6axt6N0ZGbENlUUFWMwBFN4A5aQlGait4KUtUckV7MaVXUuJXRTBFOhVVOB3Ce7FndxgnRqVFbXVnQzJ7apx6NWVHWE3SSspkdClmcrcWMtJjbrATTlqk");

// Core.setLicenseKey("pbsmof2.finance.go.ug,954667911227497#B0vRXZSR7SQFWV99UVyd4Uml7dkFVdMFTa5YGMxpURrYGZ73SRaN7R5syMxR5dXtGcLFEWmBzTzEmaKZ6dshUZERnephGeJFFS4EGVwZkQBlGcGZjTw4ET0RHMIFHUpFzdKZnaIJFczFlR4VVW8g4STJ7akJWUoRjNo9UO9UjM5BVeiVmeONWSHJFZ73SaohFZw46Q8IFZIZnNkZDMQpHSthlbQhjSSRzapd6TrE4SNhER6IUNQJ7SqlmcWdHaOdnW5R6NsV4V4d7dQRVevsCSERmZmpGN48USvt4NjdGRuVlRGlVW9IjR7cTMk36NKRFa6hmdpFjWqJiOiMlIsICOCVjMwIkMiojIIJCLwczN8MjM6EjM0IicfJye35XX3JSSWFURiojIDJCLiIjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiIzMjMzATMgQTMzAjMyAjMiojI4J7QiwiInVnLvdmLlNmbh9Wam9iMm3WbzJGciojIz5GRiwiIwV7bydEIulWYyJ4buh6YlRlI0ISYONkIsIyN9QzNyITMxkzN6YDN5kjI0ICZJJCL355W0IyZsZmIsU6csFmZ0IiczRmI1pjIs9WQisnOiQkIsISP3c6K8EGOwEjeiVXW7E4bwIXYi3CVjh6RsdDR4tiNyRWN9N7aON5aN3CWTl4R8cVanJ5SENHWGJFZrdURWtUQ6kHMYhmSKFHe0N5a5EUU6Zkb4Y5K7lUYRJ7cnlmYwtpV");

export default function App({ store, persistor, basename, client }) {
  return (
    /* ApolloProvider with client */
      <ApolloProvider client={client}>
        <Session>
          {/* Provide Redux store */}
          <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
              {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
              <React.Suspense fallback={<LayoutSplashScreen />}>
                {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                <BrowserRouter basename={basename}>
                  {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                  <MaterialThemeProvider>
                    {/* Provide `react-intl` context synchronized with Redux state.  */}
                    <I18nProvider>
                      {/* Render routes with provided `Layout`. */}
                      <ScreenLockProvider>
                        <NotificationProvider>              
                          <Routes />
                          <ReduxToastr
                            timeOut={5000}
                            newestOnTop={false}
                            preventDuplicates
                            position="top-right"
                            getState={(state) => state.toastr} // This is the default
                            transitionIn="fadeIn"
                            transitionOut="fadeOut"
                            progressBar
                            closeOnToastrClick
                          />
                        </NotificationProvider>
                      </ScreenLockProvider>
                    </I18nProvider>
                  </MaterialThemeProvider>
                </BrowserRouter>
              </React.Suspense>
            </PersistGate>
          </Provider>
        </Session>
      </ApolloProvider>
  );
}
