import { gql } from "@apollo/client";

const GET_PROGRAMMES = gql`
  query getProgrammes {
    programmes {
      Programme_ID
      Programme_Code
      Programme_Name
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_PROGRAMMES = gql`
  query getActiveProgrammes {
    activeProgrammes {
      Programme_ID
      Programme_Code
      Programme_Name
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const COUNT_PROGRAMMES = gql`
  query countProgrammes {
    countProgrammes {
      Programme_Count
    }
  }
`;

const GET_USER_ASSIGNED_REPORT_PROGRAMMES = gql`
  query getUserAssignedReportProgrammes {
    userAssignedReportProgrammes {
      Programme_ID
      Programme_Code
      Programme_Name
      Status
    }
  }
`;

const GET_USER_ASSIGNED_REPORT_PROGRAMMES_BY_REPORT_TYPE = gql`
  query getUserAssignedReportProgrammesByReportType($Report_Type: String!) {
    userAssignedReportProgrammesByReportType(Report_Type: $Report_Type) {
      Programme_ID
      Programme_Code
      Programme_Name
      Status
    }
  }
`;

export { 
  GET_PROGRAMMES, GET_ACTIVE_PROGRAMMES, COUNT_PROGRAMMES, 
  GET_USER_ASSIGNED_REPORT_PROGRAMMES, GET_USER_ASSIGNED_REPORT_PROGRAMMES_BY_REPORT_TYPE
};
