/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import {
  getBreadcrumbsAndTitle,
  useSubheader,
} from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";

export function SubHeader() {
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
	const { menu } = useSelector((state) => state.auth, shallowEqual);

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    // const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;

    let breadcrumbs = [];
    let activeMainMenu = {};
    let activeSubMenu = {};
    
    menu && menu.forEach(m => {
      if (m && m.subMenus) {
        const subMenu = m.subMenus.filter(sm => sm && sm.Sub_Menu_Path === location.pathname);
        if(subMenu.length) {
          activeMainMenu = m;
          activeSubMenu = subMenu[0];
        }
      };
    });

    if (Object.keys(activeMainMenu).length && activeMainMenu.Process_Area && activeMainMenu.Process_Area_Category) {      
      const activeSubMenu = activeMainMenu.subMenus.find(subMenu => subMenu.Sub_Menu_Path === location.pathname);
      const activePathArray = location.pathname.split("/");
      const activePathProcessArea = activePathArray[activePathArray.length - 2];
      const capitalizedActivePathProcessArea = activePathProcessArea && activePathProcessArea[0].toUpperCase() + activePathProcessArea.slice(1);

      const firstBreadcrumb = {
        // title: activeMainMenu.Process_Area || "",
        // pathname: `/budget/#${activeMainMenu.Process_Area.toLowerCase().split(' ').join('-')}`,
        title: capitalizedActivePathProcessArea || "",
        pathname: `/budget/#${activePathProcessArea}`,
      }
      
      const secondBreadcrumb = {
        title: activeMainMenu.Process_Area_Category || "",
        pathname: `/budget/#${activePathProcessArea}-${activeMainMenu.Process_Area_Category.toLowerCase().split(' ').join('-')}`,
      }

      const thirdBreadcrumb = {
        title: activeMainMenu.Main_Menu_Name || "",
        pathname: `/budget/#${activePathProcessArea}-${activeMainMenu.Main_Menu_Name.toLowerCase().split(' ').join('-')}`,
      }

      const fourthBreadcrumb = {
        title: activeSubMenu.Sub_Menu_Name || "",
        pathname: activeSubMenu.Sub_Menu_Path || "",
      }

      breadcrumbs.push(firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb, fourthBreadcrumb);

    } else if (Object.keys(activeMainMenu).length && !activeMainMenu.Process_Area) {
      const activeMainMenuNameArray = activeMainMenu && activeMainMenu.Main_Menu_Name && activeMainMenu.Main_Menu_Name.toLowerCase().split(" ");

      const activeFirstMenu = activeMainMenuNameArray && menu && menu.find(
        m => m && m.Main_Menu_ID && m.Main_Menu_Name.toLowerCase().endsWith(
          activeMainMenuNameArray[activeMainMenuNameArray.length - 1]
        )
      );
      
      const firstBreadcrumb = {
        title: activeFirstMenu && activeFirstMenu.Main_Menu_Name && activeFirstMenu.Main_Menu_Name.split(" ")[1],
        pathname: activeFirstMenu && activeFirstMenu.subMenus && activeFirstMenu.subMenus[0] && activeFirstMenu.subMenus[0].Sub_Menu_Path,
      }

      const secondBreadcrumb = {
        title: activeMainMenu && activeMainMenu.Main_Menu_Name && activeMainMenu.Main_Menu_Name.split(" ")[0],
        pathname: activeMainMenu && activeMainMenu.subMenus && activeMainMenu.subMenus[0] && activeMainMenu.subMenus[0].Sub_Menu_Path,
      }

      const thirdBreadcrumb = {
        title: activeSubMenu && activeSubMenu.Sub_Menu_Name,
        pathname: activeSubMenu && activeSubMenu.Sub_Menu_Path,
      }

      breadcrumbs.push(firstBreadcrumb, secondBreadcrumb, thirdBreadcrumb);
    } else if (location.pathname.includes("dashboard")) {
      breadcrumbs.push({
        title: "Dashboard",
        pathname: "/dashboard"
      })
    } else if (location.pathname.includes("archives")) {
      breadcrumbs.push({
        title: "Archives",
        pathname: "/archives"
      })
    }

    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
     // eslint-disable-next-line
  }, [location.pathname]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
  }, [subheader]);

  return (
    <div
      id="kt_subheader"
      className={`subheader   ${layoutProps.subheaderCssClasses} pad10`}   
    >
      <div
        className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
      >
        {/* Info */}
        <div className="d-flex align-items-center flex-wrap mr-1">
          {/* begin::Mobile Toggle */}
          {layoutProps.subheaderMobileToggle && (
            <button
              className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
              id="kt_subheader_mobile_toggle"
            >
              <span />
            </button>
          )}
          {/* end::Mobile Toggle */}

          {/* begin::Heading */}
          <div className="d-flex flex-column">
            {/* begin::Title */}
            <h2 className="text-white font-weight-bold my-2 mr-5">
              {subheader.title}
            </h2>
            {/* end::Title */}

            <BreadCrumbs items={subheader.breadcrumbs} />
          </div>
          {/* end::Heading */}
        </div>

        {/* Toolbar */}
        <div className="d-flex align-items-center">
          <button
            type="button"
            className="btn btn-transparent-white font-weight-bold  py-3 px-6 mr-4"
          >
            Reports
          </button>
          <QuickActions />
        </div>
      </div>
    </div>
  );
}
