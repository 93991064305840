/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from "react";
import { Redirect, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./Modules/Auth";
import ErrorsPage from "./Modules/ErrorsExamples/ErrorsPage";

export function Routes() {
  const location = useLocation();
  const history = useHistory();

  const { isAuthorized, menuPaths } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
      menuPaths: auth.menuPaths,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      location &&
      location.pathname &&
      !location.pathname.startsWith("/error") &&
      location.pathname !== "/auth/login" &&
      !location.pathname.startsWith("/budget") &&
      menuPaths &&
      !menuPaths.includes(location.pathname)
    ) {
      history.push("/error/error-v1");
    }
  }, [location, history, menuPaths]);

  return (
    <Switch>
      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {!isAuthorized ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
