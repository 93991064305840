import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  RefreshTokens: "[Token Refresh] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  CreateOtpVerification: "[Create OTP Verification] Action",
  CreateMenu: "[Create Menu] Action",
  CreateMenuPaths: "[Create Menu Paths] Action",
  CreateUserRole: "[Create User Role] Action",
  CreateUserVote: "[Create User Vote] Action",
  CreateActiveFiscalYear: "[Create Active Fiscal Year] Action",
  CreatePreviousFiscalYear: "[Create Previous Fiscal Year] Action",
  CreateStages: "[Create Stages] Action",
  CreateActiveStages: "[Create Active Stages] Action",
};

const initialAuthState = {
  user: undefined,
  access_token: undefined,
  refresh_token: undefined,
  otpVerification: undefined,
  menu: undefined,
  menuPaths: undefined,
  userRole: undefined,
  userVote: undefined,
  activeFiscalYear: undefined,
  previousFiscalYear: undefined,
  stages: undefined,
  activeStages: undefined,
};

export const reducer = persistReducer(
  { 
    storage, 
    key: "v0-pbs-auth", 
    whitelist: [
      "user", "access_token", "refresh_token", "otpVerification", 
      "menu", "menuPaths", 
      "userRole", "userVote", "activeFiscalYear", "previousFiscalYear", "stages", "activeStages",
    ]
  },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { access_token, refresh_token } = action.payload;

        return { ...state, access_token, refresh_token, user: undefined };
      }
      
      case actionTypes.RefreshTokens: {
        const { access_token, refresh_token } = action.payload;

        return { ...state, access_token, refresh_token };
      }

      case actionTypes.Register: {
        const { access_token, refresh_token } = action.payload;

        return { ...state, access_token, refresh_token, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.CreateOtpVerification: {
        const { otpVerification } = action.payload;
        return { ...state, otpVerification };
      }

      case actionTypes.CreateMenu: {
        const { menu } = action.payload;
        return { ...state, menu };
      }

      case actionTypes.CreateMenuPaths: {
        const { menuPaths } = action.payload;
        return { ...state, menuPaths };
      }

      case actionTypes.CreateUserRole: {
        const { userRole } = action.payload;
        return { ...state, userRole };
      }

      case actionTypes.CreateUserVote: {
        const { userVote } = action.payload;
        return { ...state, userVote };
      }

      case actionTypes.CreateActiveFiscalYear: {
        const { activeFiscalYear } = action.payload;
        return { ...state, activeFiscalYear };
      }

      case actionTypes.CreatePreviousFiscalYear: {
        const { previousFiscalYear } = action.payload;
        return { ...state, previousFiscalYear };
      }

      case actionTypes.CreateStages: {
        const { stages } = action.payload;
        return { ...state, stages };
      }

      case actionTypes.CreateActiveStages: {
        const { activeStages } = action.payload;
        return { ...state, activeStages };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (access_token, refresh_token) => ({ type: actionTypes.Login, payload: { access_token, refresh_token } }),
  register: (access_token, refresh_token) => ({
    type: actionTypes.Register,
    payload: { access_token, refresh_token },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  refreshTokens: (access_token, refresh_token) => ({ type: actionTypes.RefreshTokens, payload: { access_token, refresh_token } }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  createOtpVerification: (otpVerification) => ({ type: actionTypes.CreateOtpVerification, payload: { otpVerification } }),
  createMenu: (menu) => ({ type: actionTypes.CreateMenu, payload: { menu } }),
  createMenuPaths: (menuPaths) => ({ type: actionTypes.CreateMenuPaths, payload: { menuPaths } }),
  createUserRole: (userRole) => ({ type: actionTypes.CreateUserRole, payload: { userRole } }),
  createUserVote: (userVote) => ({ type: actionTypes.CreateUserVote, payload: { userVote } }),
  createActiveFiscalYear: (activeFiscalYear) => ({ type: actionTypes.CreateActiveFiscalYear, payload: { activeFiscalYear } }),
  createPreviousFiscalYear: (previousFiscalYear) => ({ type: actionTypes.CreatePreviousFiscalYear, payload: { previousFiscalYear } }),
  createStages: (stages) => ({ type: actionTypes.CreateStages, payload: { stages } }),
  createActiveStages: (activeStages) => ({ type: actionTypes.CreateActiveStages, payload: { activeStages } }),
};
