import React, { useState, useEffect } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { useQuery } from "@apollo/client";
// import { useSelector, shallowEqual } from "react-redux";
import { Helmet } from "react-helmet";
import { Card, CardBody } from "../../../_metronic/_partials/controls";
import { CardHeader } from "../../../_metronic/_partials/controls";

import AgGridTable from "../Table/AgGridTable";
import { saveAs } from "file-saver";
import ReactSelect from "../Helpers/ReactSelect";
import { GET_FISCAL_YEARS } from "../Administration/_Commons/Constants/Queries/FiscalYears";
import { GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_VOTE } from "./_Commons/Constants/Queries/Report";
import { groupBy } from "../Helpers/Utilities";
import { GET_ACTIVE_CG_VOTES } from "../CG/_Commons/Constants/Queries/CgVotes";
import { GET_ACTIVE_LG_VOTES } from "../LG/_Commons/Constants/Queries/LgVotes";
import { reportGeneratorUploadLink } from "../../../redux/setupApolloClient";

function ArchivesPage() {
  // const { user } = useSelector((state) => state.auth, shallowEqual);

  const [selectedReportType, setSelectedReportType] = useState(null);
  const reportTypesLoading = false;
  const reportTypes = [
    {
      label: "CG",
      value: "CG",
    },
    {
      label: "CG BOT",
      value: "CGBOT",
    },
    {
      label: "LG",
      value: "LG",
    },
    {
      label: "LG BOT",
      value: "LGBOT",
    },
  ];

  const [selectedYear, setSelectedYear] = useState(null);
  const [years, setYears] = useState([]);
  const [yearsLoading, setYearsLoading] = useState(false);

  const [selectedVote, setSelectedVote] = useState(null);

  const [cgVotes, setCgVotes] = useState([]);
  const [cgVotesLoading, setCgVotesLoading] = useState(false);

  const [lgVotes, setLgVotes] = useState([]);
  const [lgVotesLoading, setLgVotesLoading] = useState(false);

  const [selectedReport, setSelectedReport] = useState([]);
  const [reports, setReports] = useState([]);
  const [reportsLoading, setReportsLoading] = useState(false);

  const [reportFiles, setReportFiles] = useState([]);
  const [reportFilesLoading, setReportFilesLoading] = useState(false);

  const handleGetFiscalYears = useQuery(GET_FISCAL_YEARS);
  const handleGetCgVotes = useQuery(GET_ACTIVE_CG_VOTES);
  const handleGetLgVotes = useQuery(GET_ACTIVE_LG_VOTES);

  const handleGetReportArchives = useQuery(
    GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_VOTE,
    {
      variables: {
        type: selectedReportType?.value || "",
        year: selectedYear?.value || 0,
        vote: selectedVote?.value || 0,
      },
      skip: !(
        selectedReportType?.value ||
        selectedYear?.value ||
        selectedVote?.value
      ),
    }
  );

  const gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
    },
    columnDefs: [
      {
        minWidth: 500,
        headerName: "Report File",
        field: "Report_File",
        cellStyle: {
          textAlign: "left",
        },
      },
      {
		headerName: "Preview/Download",
		cellStyle: {
			textAlign: "center",
		},
		sortable: false,
		filter: false,
		minWidth: 200,
		cellRendererFramework: (params) => {
			return (
				<i
					className="btn flaticon2-download text-warning icon-md p-0 m-0"
					onClick={() => {
						saveAs(`${reportGeneratorUploadLink}/${params?.data?.Report_File}`, `${params?.data?.Report_File}`);
					}}
				></i>
			);
		},
	},
    ],
  };

  // load fiscal years
  useEffect(() => {
    const { loading, error, data } = handleGetFiscalYears;
    setYearsLoading(loading);
    if (error) toastr.error("Could not load years", error.message);
    if (!loading && data) {
      const fiscalYears = data.fiscalYears.map((type) => {
        return {
          value: type.Year_ID,
          // value: type.Fiscal_Year,
          label: type.Fiscal_Year,
        };
      });
      setYears(fiscalYears);
    }
  }, [handleGetFiscalYears]);

  // load cg votes
  useEffect(() => {
    const { loading, error, data } = handleGetCgVotes;
    setCgVotesLoading(loading);
    if (error) toastr.error("Could not load cg votes", error.message);
    if (!loading && data) {
      const votes = data.activeCgVotes.map((vote) => {
        return {
          value: vote.Vote_ID,
          // value: vote.Vote_Code,
          label: `${vote.Vote_Code} - ${vote.Vote_Name}`,
        };
      });
      setCgVotes(votes);
    }
  }, [handleGetCgVotes]);

  // load lg votes
  useEffect(() => {
    const { loading, error, data } = handleGetLgVotes;
    setLgVotesLoading(loading);
    if (error) toastr.error("Could not load lg votes", error.message);
    if (!loading && data) {
      const votes = data.activeLgVotes.map((vote) => {
        return {
          value: vote.Vote_ID,
          // value: vote.Vote_Code,
          label: `${vote.Vote_Code} - ${vote.Vote_Name}`,
        };
      });
      setLgVotes(votes);
    }
  }, [handleGetLgVotes]);

  useEffect(() => {
    if (
      selectedReportType?.value &&
      selectedYear?.value &&
      selectedVote?.value
    ) {
      const { loading, error, data } = handleGetReportArchives;
      setReportsLoading(loading);
      if (error) toastr.error("Failed to load archived reports", error.message);
      if (!loading && data) {
		const reportsByCategory = groupBy(data?.getArchivedReportsByTypeYearAndVote || [], "Report_Name");
        const reports =  Object.keys(reportsByCategory || {}).map((Report_Name) => {
			const reports = (reportsByCategory[Report_Name] || []);
			return {
				value: Report_Name,
				label: Report_Name,
				files: reports, 
			}
		});
        setReports(reports);
      }
    }
  }, [handleGetReportArchives, selectedReportType, selectedYear, selectedVote]);

  const handleChangeReportType = (reportType) => {
    setSelectedReportType(
      reportType?.value && reportType?.label ? reportType : null
    );
    setSelectedVote(null);
    setSelectedReport(null);
	setReportFiles([]);
	setReportFilesLoading(false);
  };

  const handleChangeYear = (year) => {
    setSelectedYear(year?.value && year?.label ? year : null);
    setSelectedVote(null);
    setSelectedReport(null);
	setReportFiles([]);
	setReportFilesLoading(false);
  };

  const handleChangeVote = (vote) => {
    setSelectedVote(vote?.value && vote?.label ? vote : null);
    setSelectedReport(null);
	setReportFiles([]);
	setReportFilesLoading(false);
  };

  const handleChangeReport = (report) => {
    setSelectedReport(report?.value && report?.label ? report : null);
	setReportFiles(report?.files || []);
	setReportFilesLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>PBS | Archives</title>
      </Helmet>

      <Card>
        <CardHeader title="Archives" />
        <CardBody>
          <div className="forms px-5">
            <Form>
              <Row>
                <Col md="3">
                  <Form.Group controlId="Report_Type">
                    <Form.Label className="font-weight-bold">
                      Report Type <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      value={selectedReportType}
                      name="Report_Type"
                      options={reportTypes}
                      onChange={handleChangeReportType}
                      isLoading={reportTypesLoading}
                    />
                  </Form.Group>
                </Col>
                <Col md="3">
                  <Form.Group controlId="Year">
                    <Form.Label className="font-weight-bold">
                      Fiscal Year <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      isDisabled={!selectedReportType?.value}
                      value={selectedYear}
                      name="Year"
                      options={years}
                      onChange={handleChangeYear}
                      isLoading={yearsLoading}
                    />
                  </Form.Group>
                </Col>
                {["LG", "LGBOT"].includes(selectedReportType?.value) ? (
                  <Col md="3">
                    <Form.Group controlId="LgVote">
                      <Form.Label className="font-weight-bold">
                        Lg Vote <span className="text-danger">*</span>
                      </Form.Label>
                      <ReactSelect
                        isDisabled={!selectedYear?.value}
                        value={selectedVote}
                        name="LgVote"
                        options={lgVotes}
                        onChange={handleChangeVote}
                        isLoading={lgVotesLoading}
                      />
                    </Form.Group>
                  </Col>
                ) : (
                  <Col md="3">
                    <Form.Group controlId="CgVote">
                      <Form.Label className="font-weight-bold">
                        Cg Vote <span className="text-danger">*</span>
                      </Form.Label>
                      <ReactSelect
                        isDisabled={!selectedYear?.value}
                        value={selectedVote}
                        name="CgVote"
                        options={cgVotes}
                        onChange={handleChangeVote}
                        isLoading={cgVotesLoading}
                      />
                    </Form.Group>
                  </Col>
                )}
                <Col md="3">
                  <Form.Group controlId="Report">
                    <Form.Label className="font-weight-bold">
                      Report <span className="text-danger">*</span>
                    </Form.Label>
                    <ReactSelect
                      isDisabled={!selectedVote?.value}
                      value={selectedReport}
                      name="Report"
                      options={reports}
                      onChange={handleChangeReport}
                      isLoading={reportsLoading}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>

          <AgGridTable
            rowData={reportFiles}
            gridOptions={gridOptions}
            loading={reportFilesLoading}
          />
        </CardBody>
      </Card>
    </>
  );
}

export default ArchivesPage;
