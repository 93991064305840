import React, { Suspense, lazy, useEffect } from "react";
import { getSession, saveSession } from "react-session-persist";
import { Redirect, Switch, useLocation, useHistory } from "react-router-dom";
import { useSelector, shallowEqual } from "react-redux";
import LockScreen from "react-lock-screen";
import LockScreenModal from "./LockScreenModal";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { useScreenLock } from "./screenLock";
import { AuditPage } from "./pages/AuditPage";
import ArchivesPage from "./Modules/Reports/ArchivesPage";
const UserProfilePage = lazy(() => import("./Modules/UserProfile/UserProfilePage"));
const AdministrationPage = lazy(() => import("./Modules/Administration/AdministrationPage"));
const GeneralMastersPage = lazy(() => import("./Modules/Administration/GeneralMastersPage"));
const LgMastersPage = lazy(() => import("./Modules/LG/Masters/LgMastersPage"));
const CGPage = lazy(() => import("./Modules/CG/CGPage"));
const ReportsPage = lazy(() => import("./Modules/Reports/ReportsPage"));
const BudgetPage = lazy(() => import("./Modules/Budget/BudgetsPage"));

export default function BasePage() {
  const { user } = useSelector((state) => state.auth, shallowEqual);
  const location = useLocation();
  const history = useHistory();
  const screenLock = useScreenLock();

  useEffect(() => {
    const getSessionData = async () => {
      const session = await getSession();

      if (typeof session === "undefined") history.push("/logout");

      if (session && !session.suppression && screenLock.isLocked) {
        await saveSession({ suppression: true });
      } else if (session && session.suppression && !screenLock.isLocked) {
        screenLock.setIsLocked(true);
      }
    };
    getSessionData();
  });

  useEffect(() => {
    const isTempPassword = user && user.Is_Temp_Password;
    if (isTempPassword && location.pathname !== "/user-profile/change-password") {
      history.push("/user-profile/change-password");
    }
  }, [location, user, history]);

  return (
    <LockScreen timeout={screenLock.isLocked ? 0 : 900000} ui={(setLock) => <LockScreenModal setLock={setLock} />}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          <Redirect exact from="/" to="/dashboard" />

          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/audit" component={AuditPage} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/admin" component={AdministrationPage} />
          <ContentRoute path="/general-masters" component={GeneralMastersPage} />
          <ContentRoute path="/cg" component={CGPage} />
          <ContentRoute path="/lg-masters" component={LgMastersPage} />
          <ContentRoute path="/user-profile" component={UserProfilePage} />
          <ContentRoute path="/reports" component={ReportsPage} />
          <ContentRoute path="/budget" component={BudgetPage} />
          <ContentRoute path="/archives" component={ArchivesPage} />
          <Redirect to="/error/error-v1" />
        </Switch>
      </Suspense>
    </LockScreen>
  );
}
