import { gql } from "@apollo/client";

const GET_LOGS = gql`
  query getLogs {
    logs {
      _id
      Service_Name
      Log_Type
      Log_Details
      Created_Date
    }
  }
`;

const GET_LOGS_IN_DATE_RANGE = gql`
  query getLogsInDateRange($StartDate: DateTime!, $EndDate: DateTime!, $Offset: Float!, $Limit: Float!) {
    logsInDateRange(StartDate: $StartDate, EndDate: $EndDate, Offset: $Offset, Limit: $Limit) {
      dataList {
        _id
        Service_Name
        Log_Type
        Log_Details
        Created_Date
      }
      availableDataSize
    }
  }
`;

const GET_LOG_ERRORS = gql`
  query getLogErrors {
    logErrors {
      _id
      Service_Name
      Log_Type
      Log_Details
      Created_Date
    }
  }
`;

const GET_LOG_WARNINGS = gql`
  query getLogWarnings {
    logWarnings {
      _id
      Service_Name
      Log_Type
      Log_Details
      Created_Date
    }
  }
`;

export { GET_LOGS, GET_LOGS_IN_DATE_RANGE, GET_LOG_ERRORS, GET_LOG_WARNINGS };
