import React, { useState, useEffect } from "react";
import { getSession, saveSession } from 'react-session-persist';
import { useHistory } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { useSelector, shallowEqual, connect } from "react-redux";
import { Button, Modal, Form, Spinner } from "react-bootstrap";
import publicIp from "public-ip";
import { LOGIN_MUTATION } from "./Modules/Administration/_Commons/Constants/Queries/Auth";
import { useScreenLock } from "./screenLock";
import * as auth from "./Modules/Auth/_redux/authRedux";

const LockScreenModal = ({ setLock, logout }) => {
	const { user } = useSelector((state) => state.auth, shallowEqual);
  const history = useHistory();
	const screenLock = useScreenLock();
	const [userLogin] = useMutation(LOGIN_MUTATION);
	
	const [errorMessage, setErrorMessage] = useState("");
	const [password, setPassword] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		(async() => {
			const session = await getSession();
			if (!session.suppression && !screenLock.isLocked) {
				await saveSession({"suppression": true});
				setLock(true);
				screenLock.setIsLocked(true);
				// history.push("/logout");
			}
			// history.push("/logout");
		})()
	});

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (!password.trim()) {
			setErrorMessage("Please provide a password");
			return;
		}
		
		setIsSubmitting(true);

		try {
			const loginResponse = await userLogin({ variables: { ipAddress: await publicIp.v4(), username: user.User_Name, password: password } });

			if (
				loginResponse.data &&
				loginResponse.data.login &&
				loginResponse.data.login.access_token &&
				loginResponse.data.login.refresh_token
			) {
        await saveSession({"suppression": false});
				setLock(false);
				screenLock.setIsLocked(false);
				setIsSubmitting(false);
			} else {
				setIsSubmitting(false);
				setErrorMessage("Invalid Password");
			}
		} catch (error) {
			setIsSubmitting(false);
			setErrorMessage("Invalid Password");
		}

	}

	return (
		<Modal
			backdrop="static"
			show={true}
			aria-labelledby="screen-locker-modal"
			centered
		>
			<Modal.Body
				className="overlay overlay-block cursor-default m-0"
				style={{ background: "#ffcc33", border: "2px  solid #000" }}
			>
				<div className="text-center">
					<h3 className="text-dark font-weight-bold">Just to be safe, we locked the screen</h3>
				</div>

				<div className="messages my-5">
					{
						errorMessage ? (
							<p className="text-danger font-weight-bold">{errorMessage}</p>
						) : (
							<p className="text-dark font-weight-bold">Enter password to unlock</p>
						)
					}
				</div>

				<div id="lockPasswordForm">
					<Form onSubmit={handleSubmit} autoComplete="off">
						<Form.Group controlId="menuSearch">
							<Form.Control type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} style={{border: "1px solid #000"}} />
						</Form.Group>

						<Button type="button" className="w-25 text-center font-weight-bold" variant="outline-dark" style={{ fontSize: 15 }} disabled={isSubmitting} onClick={() => history.push('/logout')}>Logout</Button>
						<Button type="submit" className="text-center font-weight-bold" variant="dark" style={{ fontSize: 15, float: "right" }} disabled={isSubmitting}>{isSubmitting ? (<> Unlocking <Spinner animation="border" variant="warning" size="sm" className="mb-1" /> </>) : "Unlock"}</Button>
					</Form>
				</div>

			</Modal.Body>
		</Modal>
	);
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.access_token) }),
  auth.actions
)(LockScreenModal);
