import { gql } from "@apollo/client";

const GET_DASHBOARD_REPORTS_GRID_DATA = gql`
  query dashboardReportsGridData(
    $Report_Type: String!
    $Report_Category: String!
    $Active_Year_ID: Float!
    $Previous_Year_ID: Float!
  ) {
    dashboardReportsGridData(
      Report_Type: $Report_Type
      Report_Category: $Report_Category
      Active_Year_ID: $Active_Year_ID
      Previous_Year_ID: $Previous_Year_ID
    ) {
      Report_ID
      Report_Name
      Internal_Report_Name
      Report_Type
      Is_DashBoard
      Consolidated
      NotInitiated
      Initiated
      InProgress
      Rejected
      Approved
      Stage
    }
  }
`;

const GET_DASHBOARD_REPORT_DETAILS = gql`
  query dashboardReportDetails(
    $Report_Type: String!
    $Report_Category: String!
    $Active_Year_ID: Float!
    $Previous_Year_ID: Float!
    $Report_ID: Float!
    $Output: String!
  ) {
    dashboardReportDetails(
      Report_Type: $Report_Type
      Report_Category: $Report_Category
      Active_Year_ID: $Active_Year_ID
      Previous_Year_ID: $Previous_Year_ID
      Report_ID: $Report_ID
      Output: $Output
    ) {
      Vote_ID
      Vote_Code
      Vote_Name
      Programme_ID
      Programme_Code
      Programme_Name
      SEPC_ID
      Parastatal_Code
      Parastatal_Name
      Task_Status
      Initiator_Status
      First_Level_Status
      Second_Level_Status
      Third_Level_Status
      Fourth_Level_Status
      Fifth_Level_Status
      Sixth_Level_Status
      Seventh_Level_Status
    }
  }
`;

const GET_DASHBOARD_REPORTS_GRID_DATA_VOTE = gql`
  query dashboardReportsGridDataVote(
    $Report_Type: String!
    $Report_Category: String!
    $Active_Year_ID: Float!
    $Previous_Year_ID: Float!
  ) {
    dashboardReportsGridDataVote(
      Report_Type: $Report_Type
      Report_Category: $Report_Category
      Active_Year_ID: $Active_Year_ID
      Previous_Year_ID: $Previous_Year_ID
    ) {
      Report_ID
      Report_Name
      Internal_Report_Name
      Report_Type
      Is_DashBoard
      Consolidated
      NotInitiated
      Initiated
      InProgress
      Rejected
      Approved
      Stage
    }
  }
`;

const GET_DASHBOARD_REPORTS_GRID_DATA_SEPC = gql`
  query dashboardReportsGridDataSepc(
    $Report_Type: String!
    $Report_Category: String!
    $Active_Year_ID: Float!
    $Previous_Year_ID: Float!
  ) {
    dashboardReportsGridDataSepc(
      Report_Type: $Report_Type
      Report_Category: $Report_Category
      Active_Year_ID: $Active_Year_ID
      Previous_Year_ID: $Previous_Year_ID
    ) {
      Report_ID
      Report_Name
      Internal_Report_Name
      Report_Type
      Is_DashBoard
      Consolidated
      NotInitiated
      Initiated
      InProgress
      Rejected
      Approved
      Stage
    }
  }
`;
const GET_DASHBOARD_REPORTS_GRID_DATA_LG_VOTE = gql`
  query dashboardReportsGridDataLgVote(
    $Report_Type: String!
    $Report_Category: String!
    $Active_Year_ID: Float!
    $Previous_Year_ID: Float!
  ) {
    dashboardReportsGridDataLgVote(
      Report_Type: $Report_Type
      Report_Category: $Report_Category
      Active_Year_ID: $Active_Year_ID
      Previous_Year_ID: $Previous_Year_ID
    ) {
      Report_ID
      Report_Name
      Internal_Report_Name
      Report_Type
      Is_DashBoard
      Consolidated
      NotInitiated
      Initiated
      InProgress
      Rejected
      Approved
      Stage
    }
  }
`;

export { GET_DASHBOARD_REPORTS_GRID_DATA, GET_DASHBOARD_REPORT_DETAILS,
  GET_DASHBOARD_REPORTS_GRID_DATA_VOTE,GET_DASHBOARD_REPORTS_GRID_DATA_SEPC,
  GET_DASHBOARD_REPORTS_GRID_DATA_LG_VOTE };
