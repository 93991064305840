import React from "react";
import { useHistory } from "react-router-dom";
import { Avatar, Chip } from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import {toAbsoluteUrl} from "../../../_metronic/_helpers";

export function ErrorPage1() {
  const history = useHistory();
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg1.jpg")})`
        }}
      >
        <h1
          className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
          style={{ fontSize: "150px" }}
        >
          404
        </h1>
        <p className="font-size-h3 font-weight-light">
          OOPS! Something went wrong here
        </p>
        <div className="d-inline">
          <Chip
            size="large"
            color="primary"
            avatar={
              <Avatar>
                <ArrowBackIcon size="small" style={{ color: "#fff", padding: "2px" }} />
              </Avatar>
            }
            label="Back"
            onClick={() => history.go(-2)}
            variant="outlined"
            style={{
              fontWeight: "bolder",
              fontSize: 20,
            }}
          />
          &nbsp;
          &nbsp;
          &nbsp;
          <Chip
            size="large"
            color="primary"
            avatar={
              <Avatar>
                <HomeIcon size="small" style={{ color: "#fff", padding: "2px" }} />
              </Avatar>
            }
            label="Dashboard"
            onClick={() => history.push('/dashboard')}
            variant="outlined"
            style={{
              fontWeight: "bolder",
              fontSize: 20,
            }}
          />
        </div>
      </div>
    </div>
  );
}
