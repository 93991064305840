import { gql } from "@apollo/client";

const GET_CG_VOTES = gql`
  query getCgVotes {
    cgVotes {
      Vote_ID
      Vote_Code
      Vote_Name
      Status
      MPS_Secretariate
      Is_NTR
      Is_AIA
      Is_MPS
      Is_New
      Is_IFMS
      Is_PS
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_CG_VOTES = gql`
  query getActiveCgVotes {
    activeCgVotes {
      Vote_ID
      Vote_Code
      Vote_Name
      Status
      MPS_Secretariate
      Is_NTR
      Is_AIA
      Is_MPS
      Is_New
      Is_IFMS
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const COUNT_CG_VOTES = gql`
  query countCgVotes {
    countCgVotes {
      CgVote_Count
    }
  }
`;

const GET_ACTIVE_CG_ISMPS_VOTES = gql`
  query cgActiveISMPSVotes {
    cgActiveISMPSVotes {
      Vote_ID
      Vote_Code
      Vote_Name
    }
  }
`;

const GET_CG_VOTES_GRID_DATA = gql`
  query cgVotesGridData {
    cgVotesGridData {
      Vote_ID
      Vote_Code
      Vote_Name
      Status
      MPS_Secretariate
      Is_NTR
      Is_AIA
      Is_MPS
      Is_New
      Is_IFMS
      Is_PS
      Created_Date
      Modified_Date
      Created_By
      Modified_By
      IsMPS_Vote_ID
      IsMPS_Vote_Code
      IsMPS_Vote_Name
    }
  }
`;

const GET_ACTIVE_CG_ISAIA_VOTES = gql`
  query cgActiveIsAIAVote($Vote_ID: Float!) {
    cgActiveIsAIAVote(Vote_ID: $Vote_ID) {
      Vote_ID
      Vote_Code
      Vote_Name
      Is_AIA
    }
  }
`;

const GET_ACTIVE_CG_ISIFMS_VOTES = gql`
  query cgActiveIsIfmsVote($Vote_ID: Float!) {
    cgActiveIsIfmsVote(Vote_ID: $Vote_ID) {
      Vote_ID
      Vote_Code
      Vote_Name
      Is_IFMS
    }
  }
`;

export { GET_CG_VOTES, GET_ACTIVE_CG_VOTES, COUNT_CG_VOTES, GET_ACTIVE_CG_ISMPS_VOTES, 
  GET_CG_VOTES_GRID_DATA, GET_ACTIVE_CG_ISAIA_VOTES, GET_ACTIVE_CG_ISIFMS_VOTES };
