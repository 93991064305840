import { gql } from "@apollo/client";

const GET_LG_VOTES = gql`
  query getLgVotes {
    lgVotes {
      Vote_ID
      Vote_Code
      Vote_Name
      Status
      Sub_Region_ID {
        Sub_Region_ID
        Sub_Region_Code
        Sub_Region
      }
      Is_MC
      Is_IFMS
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;
const GET_ACTIVE_LG_VOTES = gql`
  query getActiveLgVotes {
    activeLgVotes {
      Vote_ID
      Vote_Code
      Vote_Name
      Status
      Is_MC
      Is_IFMS
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const COUNT_LG_VOTES = gql`
  query countLgVotes {
    countLgVotes {
      LgVote_Count
    }
  }
`;

const GET_SUB_REGION_BY_VOTES = gql`
    query getActiveLgSubRegionsByVotes(
      $id: Float!
    ) {
      activeLgSubRegionsByVotes(id: $id) {
          Vote_ID
          Vote_Code
          Vote_Name
          Status
          Sub_Region_ID {
            Sub_Region_ID
            Sub_Region_Code
            Sub_Region
          }
          Is_MC
          Is_IFMS
          Created_Date
          Modified_Date
          Created_By
          Modified_By
        }
    }
`;

const GET_IS_MC_BY_VOTE_ID = gql`
    query getlgVote(
      $Vote_ID: Float!
    ) {
      lgVote(id: $Vote_ID) {
          Vote_ID
          Vote_Code
          Vote_Name
          Status
          Is_MC
          Is_IFMS
          Created_Date
          Modified_Date
          Created_By
          Modified_By
        }
    }
`;

const GET_ACTIVE_LG_ISIFMS_VOTES = gql`
  query lgActiveIsIfmsVote($Vote_ID: Float!) {
    lgActiveIsIfmsVote(Vote_ID: $Vote_ID) {
      Vote_ID
      Vote_Code
      Vote_Name
      Is_IFMS
    }
  }
`;

const GET_ACTIVE_VOTES_BY_VOTEID = gql`
  query lgVotesSP($Vote_ID: Float!) {
    lgVotesSP(Vote_ID: $Vote_ID) {
      Vote_Code
    }
  }
`;

export { GET_LG_VOTES, GET_ACTIVE_LG_VOTES, COUNT_LG_VOTES, 
         GET_SUB_REGION_BY_VOTES,GET_IS_MC_BY_VOTE_ID, GET_ACTIVE_LG_ISIFMS_VOTES,
         GET_ACTIVE_VOTES_BY_VOTEID
        };
