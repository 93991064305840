/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import NumberFormat from 'react-number-format';
import { useHtmlClassService } from "../../../layout";
import { useQuery } from "@apollo/client";
import { GET_BUDGET_MTEFS } from "../../../../app/Modules/CG/_Commons/Constants/Queries/CgMtef";
import { toastr } from "react-redux-toastr";

export function StatsWidget11({ className, symbolShape, baseColor }) {
  const { activeFiscalYear } = useSelector((state) => state.auth);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${baseColor}`
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${baseColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, baseColor]);

  const [budgetMTEF, setBudgetMTEF] = useState(0);

  const handleGetBudgetMtef = useQuery(GET_BUDGET_MTEFS);

  useEffect(() => {
    const { loading, error, data } = handleGetBudgetMtef;
    if (error) toastr.error("Failed", error.message);
    if (!loading && data && data.budgetMTEF) setBudgetMTEF(data.budgetMTEF);
  }, [handleGetBudgetMtef]);

  useEffect(() => {
    const element = document.getElementById("kt_stats_widget_11_chart");

    if (!element) {
      return;
    }

    const options = getChartOption(layoutProps);

    const Y0 = activeFiscalYear ? ((activeFiscalYear.Fiscal_Year.split("-")).map(year => Number(year) - 1)).join(" - ") : "Y0";
    const Y1 = activeFiscalYear ? ((activeFiscalYear.Fiscal_Year.split("-")).map(year => Number(year))).join(" - ") : "Y1";

    options.series = [
      {
        name: Y0,
        data: [
          parseInt(budgetMTEF.Y0_Wage),
          parseInt(budgetMTEF.Y0_NonWage), 
          parseInt(budgetMTEF.Y0_Donor), 
          parseInt(budgetMTEF.Y0_AIA), 
          parseInt(budgetMTEF.Y0_GoUDevt),
        ]
      }, 
      {
        name: Y1,
        data: [
          parseInt(budgetMTEF.Y1_Wage),
          parseInt(budgetMTEF.Y1_NonWage), 
          parseInt(budgetMTEF.Y1_Donor), 
          parseInt(budgetMTEF.Y1_AIA), 
          parseInt(budgetMTEF.Y1_GoUDevt),
        ]
      }
    ]

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, budgetMTEF, activeFiscalYear]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-body p-0">
        <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
          <span
            className={`symbol ${symbolShape} symbol-50 symbol-light-${baseColor} mr-2`}
          >
            <span className="symbol-label">
              <span className={`svg-icon svg-icon-xl svg-icon-${baseColor}`}>
                <i className="flaticon-suitcase text-success icon-3x"></i>
              </span>
            </span>
          </span>
          <div className="d-flex flex-column text-right">
            <span className="text-dark-75 font-weight-bolder font-size-h3">
              <NumberFormat value={budgetMTEF.Y1_Budget ? Math.round(budgetMTEF.Y1_Budget) : 0} displayType={'text'} thousandSeparator={true} />
              {" "} T
            </span>
            <span className="text-muted font-weight-bold mt-2">
              Fiscal Year Budget
            </span>
          </div>
        </div>
        <div
          id="kt_stats_widget_11_chart"
          className="card-rounded-bottom"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
}

function getChartOption() {
  const options = {
    series: [],
    chart: {
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
        tools:{
          download:false
        }
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: '12px',
        colors: ['#fff']
      }
    },
    stroke: {
      show: true,
      width: 1,
      colors: ['#fff']
    },
    tooltip: {
      shared: true,
      intersect: false
    },
    xaxis: {
      categories: ["Wage", "Non Wage", "Donor", "AIA", "GoU Devt"],
    },
  };

  return options;
}
