import { gql } from "@apollo/client";

const GET_USER_PROFILE = gql`
  query getUserProfile {
    getUserProfile {
      User_ID
      User_Name
      First_Name
      Last_Name
      Email
      Mobile_Number
      Status
      Is_Locked
      Is_Temp_Password
      Multi_Login
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
    }
  }
`;

const GET_USERS = gql`
  query getUsers {
    users {
      User_ID
      User_Name
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
      First_Name
      Last_Name
      Email
      Status
      Is_Locked
      Is_Temp_Password
      Mobile_Number
      Multi_Login
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_USERS = gql`
  query getActiveUsers {
    activeUsers {
      User_ID
      User_Name
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
      First_Name
      Last_Name
      Email
      Status
      Is_Locked
      Is_Temp_Password
      Mobile_Number
      Multi_Login
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_USERS_BY_CG_TYPE_AND_NOT_ASSIGNED_ROLES = gql`
  query getUsersNotAssignedRolesByCgUserType(
    $User_Type_ID: Int!
  ) {
    usersByCgUserTypeAndNotAssigndRoles(
      User_Type_ID: $User_Type_ID
    ) {
      User_ID
      User_Name
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
      First_Name
      Last_Name
      Email
      Status
      Mobile_Number
      Multi_Login
      Is_Locked
      Is_Temp_Password
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_USERS_BY_LG_TYPE_AND_NOT_ASSIGNED_ROLES = gql`
  query getUsersNotAssignedRolesByLgUserType(
    $User_Type_ID: Int!
  ) {
    usersByLgUserTypeAndNotAssigndRoles(
      User_Type_ID: $User_Type_ID
    ) {
      User_ID
      User_Name
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
      First_Name
      Last_Name
      Email
      Status
      Mobile_Number
      Is_Locked
      Is_Temp_Password
      Multi_Login
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;


const GET_USERS_ASSIGNED_ROLE = gql`
  query getUsersAssignedRole(
    $User_Type_Name: String!,
    $Role_ID: Int!,
  ) {
    usersAssignedRole(
      User_Type_Name: $User_Type_Name,
      Role_ID: $Role_ID
    ) {
      User_ID
      User_Name
      User_Type_ID {
        User_Type_ID
        User_Type_Name
      }
      First_Name
      Last_Name
      Email
      Status
      Mobile_Number
      Is_Locked
      Is_Temp_Password
      Multi_Login
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const COUNT_USERS = gql`
  query countUsers {
    countUsers {
      Users_Count
    }
  }
`;

const USER_ROLE_DETAILS = gql`
  query userRoleDetails(
    $User_ID: Int!,
  ) {
    userRoleDetails(
      User_ID: $User_ID
    ) {
      user {
        User_ID
        User_Name
        User_Type_ID {
          User_Type_ID
          User_Type_Name
        }
        First_Name
        Last_Name
        Email
        Status
        Mobile_Number
        Is_Locked
        Is_Temp_Password
        Multi_Login
        Created_Date
        Modified_Date
        Created_By
        Modified_By
      }
      userRole {
        Role_ID
        Title
        Generate_Permission
        Approve_Permission
        Is_OTP
        Status
        Created_Date
        Modified_Date
        Created_By
        Modified_By
      },
      userVote {
        Vote_ID
        Vote_Code
        Vote_Name
        Status
        Created_Date
        Modified_Date
        Created_By
        Modified_By
      },
      isSepc,
      userSepc {
        SEPC_ID
        Parastatal_Code
        Parastatal_Name
        Department_ID
        Vote_By_Sub_SubProgID
        Status
        Created_Date
        Modified_Date
        Created_By
        Modified_By
      },
      programmeIds
    }
  }
`;

export { 
  GET_USER_PROFILE,
  GET_USERS, GET_ACTIVE_USERS, GET_USERS_ASSIGNED_ROLE,
  GET_USERS_BY_CG_TYPE_AND_NOT_ASSIGNED_ROLES, 
  GET_USERS_BY_LG_TYPE_AND_NOT_ASSIGNED_ROLES,
  COUNT_USERS, USER_ROLE_DETAILS,
};
