import { gql } from "@apollo/client";

const GET_AUDITS = gql`
  query getAudits {
    audits {
      _id
      Table_Name
      Audit_Type
      Old_Values
      New_Values
      Created_Date
      Created_By
    }
  }
`;

const GET_AUDITS_IN_DATE_RANGE = gql`
  query getAuditsInDateRange($StartDate: DateTime!, $EndDate: DateTime!, $Offset: Float!, $Limit: Float!) {
    auditsInDateRange(StartDate: $StartDate, EndDate: $EndDate, Offset: $Offset, Limit: $Limit) {
      dataList {
        _id
        Table_Name
        Audit_Type
        Old_Values
        New_Values
        Created_Date
        Created_By
      }
      availableDataSize
    }
  }
`;

export { GET_AUDITS, GET_AUDITS_IN_DATE_RANGE };
