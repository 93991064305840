export const No_of_Working_Rows = 10000;

export const populateColumn = (sheet, columnLetter, headerName, width, valuesArray = []) => {
  const col = sheet.getColumn(columnLetter);
  col.width = width;
  col.values = [headerName, ...valuesArray];
};

export const populateColumnWithFormat = (sheet, columnLetter, headerName, width, cellFormat, valuesArray = []) => {
  const col = sheet.getColumn(columnLetter);
  col.width = width;
  col.values = [headerName, ...valuesArray];
  for (let i = 2; i <= No_of_Working_Rows; i++) {
    sheet.getCell(`${columnLetter}${i}`).numFmt = cellFormat;
  }
};

export const createDropDownColumn = (
  sheet,
  columnLetter,
  headerName,
  width,
  sourceColumn,
  startRow,
  no_of_options,
  sourceSheet = ""
) => {
  const col = sheet.getColumn(columnLetter);
  col.header = headerName;
  col.width = width;

  for (let i = 2; i <= No_of_Working_Rows; i++) {
    sheet.getCell(`${columnLetter}${i}`).numFmt = "@";
    sheet.getCell(`${columnLetter}${i}`).dataValidation = {
      type: "list",
      formulae: [`${sourceSheet && sourceSheet + "!"}$${sourceColumn}$${startRow}:$${sourceColumn}$${startRow - 1 + no_of_options}`],
    };
  }
};

export const createLookUpAutoFillToColumn = (
  sheet,
  columnLetter,
  headerName,
  width,
  refColumn,
  sourceStartColumn,
  sourceEndColumn,
  sourceStartRow,
  no_of_options,
  valueColumnPosition,
  sourceSheet = ""
) => {
  const col = sheet.getColumn(columnLetter);
  const sourceRange = `${sourceSheet &&
    sourceSheet + "!"}$${sourceStartColumn}$${sourceStartRow}:$${sourceEndColumn}$${sourceStartRow - 1 + no_of_options}`;
  col.header = headerName;
  col.width = width;
  for (let i = 2; i <= No_of_Working_Rows; i++) {
    sheet.getCell(`${columnLetter}${i}`).value = {
      formula: `IF(${refColumn}${i}="","",VLOOKUP(${refColumn}${i},${sourceRange},${valueColumnPosition},FALSE()))`,
    };
  }
};

export const createAutoFillToColumn = (sheet, columnLetter, headerName, width, refColumn) => {
  const col = sheet.getColumn(columnLetter);
  col.header = headerName;
  col.width = width;
  for (let i = 2; i <= No_of_Working_Rows; i++) {
    sheet.getCell(`${columnLetter}${i}`).value = {
      formula: `IF(${refColumn}${i}="","",PRODUCT(${refColumn}${i},${12}))`,
    };
  }
};

export const createFilterColumn = (sheet, columnLetter, FilterLetter, headerName, width, refColumn) => {
  const col = sheet.getColumn(columnLetter);
  col.header = headerName;
  col.width = width;
  for (let i = 2; i <= No_of_Working_Rows; i++) {
    sheet.getCell(`${columnLetter}${i}`).value = {
      formula: `=FILTER(${columnLetter}${i}:${FilterLetter}${i},${FilterLetter}${i}=${refColumn}${i},"No results")`,
    };
  }
};

export const formatColumn = (sheet, columnLetter, formatObj) => {
  formatObj.forEach((obj) => {
    if (obj.hasOwnProperty("fill")) {
      sheet.getCell(`${columnLetter}${obj.row}`).fill = obj.fill;
    }
    if (obj.hasOwnProperty("border")) {
      sheet.getCell(`${columnLetter}${obj.row}`).border = obj.border;
    }
  });
};

export const unlockColumnCells = (sheet, columnLetter, startRow, endRow) => {
  for (let i = startRow; i <= endRow; i++) {
    sheet.getCell(`${columnLetter}${i}`).protection = { locked: false };
  }
};

export const formatDate = (excelDate) => new Date(Date.UTC(0, 0, excelDate, -21));
