import { gql } from "@apollo/client";

const GET_SUB_PROGRAMMES = gql`
  query getSubProgrammes {
    subProgrammes {
      SubProgramme_ID
      Programme_ID {
        Programme_ID
        Programme_Code
        Programme_Name
      }
      SubProgramme_Code
      SubProgramme_Name
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_SUB_PROGRAMMES = gql`
  query getActiveSubProgrammes {
    activeSubProgrammes {
      SubProgramme_ID
      Programme_ID {
        Programme_ID
        Programme_Code
        Programme_Name
      }
      SubProgramme_Code
      SubProgramme_Name
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_SUB_PROGRAMMES_By_PROGRAMME = gql`
  query getActiveSubProgrammesByProgramme {
    activeSubProgrammesByProgramme {
      SubProgramme_ID
      Programme_ID {
        Programme_ID
        Programme_Code
        Programme_Name
      }
      SubProgramme_Code
      SubProgramme_Name
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_ACTIVE_SUB_PROGRAMMES_By_PROGRAMME_ID = gql`
    query getActiveSubProgrammesByProgrammeID(
        $id: Float!
    ) {
      activeSubProgrammesByProgramme(id: $id) {
        SubProgramme_ID
        Programme_ID {
          Programme_ID
          Programme_Code
          Programme_Name
        }
        SubProgramme_Code
        SubProgramme_Name
        Status
        Created_Date
        Modified_Date
        Created_By
        Modified_By
        }
    }
`;

const COUNT_SUB_PROGRAMMES = gql`
  query countSubProgrammes {
    countSubProgrammes {
      SubProgramme_Count
    }
  }
`;

const LG_SUB_PROGRAMMES = gql`
  query lgSubProgrammes {
    lgSubProgrammes {
      SubProgramme_Code
    }
  }
`;

export { GET_SUB_PROGRAMMES, GET_ACTIVE_SUB_PROGRAMMES, 
         COUNT_SUB_PROGRAMMES,GET_ACTIVE_SUB_PROGRAMMES_By_PROGRAMME, 
         GET_ACTIVE_SUB_PROGRAMMES_By_PROGRAMME_ID, LG_SUB_PROGRAMMES };
