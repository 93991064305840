/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { Tab, Nav, Form } from "react-bootstrap";
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Scrollbars } from 'react-custom-scrollbars';
import { checkIsActive } from "../../../../_helpers";
import { Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles({
  list: {
    width: 350,
    overflow: "hidden",
  },
});

export function SideMenuPopup(props) {
  const classes = useStyles();
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const { menu, isMenuOpen, toggleMenu, menuData } = props;

  const [selectedTab, setSelectedTab] = useState("Menu");
  const [filterValue, setFilterValue] = useState("");
  const [filtredMenuData, setFiltredMenuData] = useState([]);

  const setTab = _tabName => {
    setSelectedTab(_tabName);
  };

  const menuName = menu ? menu.match(/[A-Z][a-z]+|[0-9]+/g).join(" ") : "";

  useEffect(() => {
    const sortedMenuData = menuData && menuData.filter(menu => menu.Main_Menu_Name.toLowerCase().includes(menuName.toLowerCase()));
    
    if (filterValue.length) {
      let filtredData = [];
      sortedMenuData && sortedMenuData.forEach(mainMenu => {
        const mainMenuSubmenus = mainMenu.subMenus && mainMenu.subMenus.filter(subMenu => 
          subMenu.Sub_Menu_Name.toLowerCase().startsWith(filterValue.toLowerCase()) || 
          subMenu.Sub_Menu_Name.toLowerCase().includes(filterValue.toLowerCase()) || 
          subMenu.Main_Menu_ID.Main_Menu_Name.toLowerCase().includes(filterValue.toLowerCase()) || 
          subMenu.Main_Menu_ID.Main_Menu_Name.toLowerCase().startsWith(filterValue.toLowerCase()) 
        );

        if (mainMenuSubmenus) {
          filtredData.push({
            ...mainMenu,
            subMenus: mainMenuSubmenus,
          });
        }
      });
      setFiltredMenuData(filtredData);
    } else {
      setFiltredMenuData(sortedMenuData);
    }

  }, [filterValue, menuData, menuName]);

  useEffect(() => {
    if (!isMenuOpen) {
      setFilterValue("");
    }
  }, [isMenuOpen]);

  return (
    <SwipeableDrawer
      open={isMenuOpen}
      onOpen={toggleMenu(menu, true)}
      onClose={toggleMenu(menu, false)}
    >
      <div
        className={classes.list}
        role="presentation"
        style={{ width: '100%', height: '100%', background: '#ECECEC' }}
      >
        <Tab.Container
          defaultActiveKey={selectedTab}
        >
          {/*begin::Header*/}
          <div className="m-2">
            <Nav
              onSelect={setTab}
              as="ul"
              role="tablist"
              className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
            >
              <Nav.Item className="nav-item" as="li">
                <Nav.Link
                  eventKey="Menu"
                  className={`nav-link ${selectedTab === "Menu" ? "active" : ""}`}
                  style={{ fontWeight: 'bolder', textTransform: 'uppercase' }}
                >
                  {menuName} Menu
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <div className="offcanvas-close mt-n1 pr-5" style={{ position: "absolute", top: "15px", right: "10px" }}>
              <a
                href="#"
                className="btn btn-xs btn-icon btn-light"
                onClick={toggleMenu(menu, false)}
              >
                <i className="ki ki-close icon-xs text-muted"></i>
              </a>
            </div>
          </div>
          {/*end::Header*/}

          {/*begin::Content*/}
          <div className="tab-content">
            <div id="menuSearchForm" style={{marign: 0, padding: 10, background: "#fff"}}>
              <Form onSubmit={e => e.preventDefault()} autoComplete="off">
                <Form.Group controlId="menuSearch">
                  <Form.Control className="menu-search-form" type="text" placeholder="Search Menu" value={filterValue} onChange={e => setFilterValue(e.target.value)} />
                </Form.Group>
              </Form>
            </div>
            <Scrollbars 
              autoHeight 
              autoHeightMin="calc(100vh - calc(23vh))" 
              style={{ minWidth: "calc(20vw)" }}
            >
              <List>
                {
                  filtredMenuData && filtredMenuData.map((mainMenu, mainMenuIndex) => {
                    if (mainMenu.subMenus) {
                      return (
                        <div key={mainMenuIndex}>
                          <ListItem key={`${mainMenu.Main_Menu_ID}${mainMenuIndex}`}>
                            <ListItemIcon style={{ minWidth: 30 }}>
                              <Icon style={{ color: '#f0af1b' }}>{mainMenu.Main_Menu_Icon}</Icon>
                            </ListItemIcon>
                            <ListItemText
                              disableTypography
                              key={`${mainMenu.Main_Menu_ID}${mainMenuIndex}`}
                              primary={<Typography type="h1" style={{ color: '#f0af1b', fontWeight: 'bolder', textTransform: 'uppercase' }}>{mainMenu.Main_Menu_Name}</Typography>}
                            />
                          </ListItem>
                          <div onClick={toggleMenu(menu, false)}>
                            {
                              mainMenu.subMenus.map((subMenu, subMenuIndex) => (
                                <ListItem key={`${mainMenuIndex}${subMenu.Sub_Menu_ID}${subMenuIndex}`} className={`text-dark ${getMenuItemActive(subMenu.Sub_Menu_Path)}`} component={Link} to={subMenu.Sub_Menu_Path}>
                                  <ListItemIcon style={{ minWidth: 20, marginLeft: 25 }}>
                                    <Icon fontSize="small" style={{ fontSize: 8 }}>fiber_manual_record</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary={subMenu.Sub_Menu_Name} />
                                </ListItem>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }

                    return null;
                  })
                }
              </List>
            </Scrollbars>
          </div>
          {/*end::Content*/}
        </Tab.Container>
      </div>
    </SwipeableDrawer >
  );
}
