import React, {useEffect} from "react";
// import {useMutation} from "@apollo/client";
// import {connect, useSelector, shallowEqual} from "react-redux";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {LayoutSplashScreen} from "../../../../_metronic/layout";
import { useScreenLock } from "../../../screenLock";
import * as auth from "../_redux/authRedux";
// import { LOGOUT_MUTATION } from "../../Administration/_Commons/Constants/Queries/Auth";

function Logout(props) {
  const { hasAuthToken, logout } = props;
  const screenLock = useScreenLock();
  // const { user } = useSelector((state) => state.auth, shallowEqual);
  // const [userLogout] = useMutation(LOGOUT_MUTATION);

  useEffect(() => { 
    const userlogOut = async () => {
      screenLock.setIsLocked(false);
      logout();
    }

    userlogOut();
   }, [logout, screenLock]);
  
  // useEffect(() => {
  //   userLogout({
  //     variables: {
  //       User_ID: user.User_ID
  //     },
  //   })
  //   .then(() => {
  //     logout();
  //   })
  //   .catch (error => {
  //     console.error(JSON.stringify(error, null, 2));
  //   });
  // }, [user, userLogout, logout]);

  return hasAuthToken ? (<LayoutSplashScreen />) : (<Redirect to="/auth/login" />);
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.access_token) }),
  auth.actions
)(Logout);
