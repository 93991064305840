/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState } from "react";
// import { useHistory } from "react-router-dom";
import { Tab, Nav } from "react-bootstrap";
// import store from "../../../../../redux/store";
import { useNotification } from "../../../../../app/Notification";
// import { UPDATE_NOTIFICATION } from "../../../../../app/Modules/_Commons/Constants/Queries/notifications";
// import { notificationClient } from "../../../../../redux/setupApolloClient";
// import { useMutation } from "@apollo/client";
// import { toastr } from "react-redux-toastr";

export function QuickPanel() {
  // const history = useHistory();
  const { notifications } = useNotification();
  const [selectedTab, setSelectedTab] = useState("Notifications");
  // const [client, setClient] = useState(null);

  // useEffect(() => setClient(notificationClient(store)), []);

  // const [handleUpdateNotification] = useMutation(UPDATE_NOTIFICATION, { client });

  const setTab = (_tabName) => {
    setSelectedTab(_tabName);
  };

  const viewNotification = (notification) => {
    // handleUpdateNotification({
    //   variables: {
    //     id: notification.id,
    //     Title: notification.Title,
    //     Description: notification.Description,
    //     Icon: notification.Icon,
    //     Category: notification.Category,
    //     User_ID: notification.User_ID,
    //     Viewed: !notification.Viewed,
    //   },
    // })
    //   .then(({ data: { updateNotification } }) => {
    //     if (updateNotification) {
    //       const updatedNotifications = notifications.filter((notification) => notification.id !== updateNotification.id);
    //       setNotifications(updatedNotifications);
    //       history.push("/reports/report-workflow-tasks");
    //     } else {
    //       toastr.error("Failed", "Notification not updated");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(JSON.stringify(error, null, 2));
    //     toastr.error("Failed", error && error.message ? error.message : "Notification not updated");
    //   });
  };

  return (
    <div id="kt_quick_panel" className="offcanvas offcanvas-left pt-5 pb-10">
      <Tab.Container defaultActiveKey={selectedTab}>
        {/*begin::Header*/}
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5">
          <Nav
            onSelect={setTab}
            as="ul"
            role="tablist"
            className="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-primary flex-grow-1 px-10"
          >
            <Nav.Item className="nav-item" as="li">
              <Nav.Link eventKey="Notifications" className={`nav-link ${selectedTab === "Notifications" ? "active" : ""}`}>
                Notifications
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <div className="offcanvas-close mt-n1 pr-5" style={{ position: "absolute", top: "15px", right: "10px" }}>
            <a href="#" className="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_panel_close">
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
        </div>
        {/*end::Header*/}

        {/*begin::Content*/}
        <div className="offcanvas-content px-10">
          <div className="tab-content">
            <div
              id="kt_quick_panel_notifications"
              role="tabpanel"
              className={`tab-pane fade pt-3 pr-5 mr-n5 scroll ps ${selectedTab === "Notifications" ? "active show" : ""}`}
            >
              <div className="mb-5">
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <a
                      href="#"
                      className={`font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1`}
                      key={notification.id}
                      onClick={() => viewNotification(notification)}
                    >
                      <div className={`d-flex align-items-center bg-light-${notification.Category} rounded p-5 mb-5`}>
                        <span className={`svg-icon svg-icon-${notification.Category} mr-5`}>
                          <i className={`${notification.Icon} text-${notification.Category}`}></i>
                        </span>
                        <div className={`d-flex flex-column flex-grow-1 mr-2`}>
                          <span>{notification.Title}</span>
                          <span className={`text-muted font-size-sm`}>{notification.Description}</span>
                        </div>
                      </div>
                    </a>
                  ))
                ) : (
                  <h4 style={{ color: "#898989" }}>No Notifications</h4>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*end::Content*/}
      </Tab.Container>
    </div>
  );
}
