import { useQuery } from "@apollo/client";
import ApexCharts from "apexcharts";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import store from "../../../../redux/store";
import { notificationClient } from "../../../../redux/setupApolloClient";
import { GET_LOGS_IN_DATE_RANGE } from "../../../../app/Modules/Administration/_Commons/Constants/Queries/Logs";
import TopRecordSelect from "../../../../app/Modules/Helpers/TopRecordSelect";

export function AdvanceTablesWidget5({ className, Start_Date,End_Date }) {
  const [warningLogs, setWarningLogs] = useState([]);
  const [errorLogs, setErrorLogs] = useState([]);
  const [verboseLogs, setVerboseLogs] = useState([]);
  const [Logs, setLogs] = useState([]);
  const [Debug, setDebug] = useState([]);
  const [client, setClient] = useState(null);

  const [LogsLimit, setLogsLimit] = useState(1000);

  useEffect(() => setClient(notificationClient(store)), []);

  // const handleGetLogs = useQuery(GET_LOGS, { client });

  const handleGetLogs = useQuery(GET_LOGS_IN_DATE_RANGE, {
    client,
    variables: {
      StartDate: Start_Date,
      EndDate: End_Date,
      Offset: 0,
      Limit: Number(LogsLimit)
    },
    skip: !(client && Start_Date && End_Date && (End_Date >= Start_Date)),
  });

  useEffect(() => {
    const { loading, error, data } = handleGetLogs;

    if (error) toastr.error("Failed to load logs graph data", error.message);

    if (!loading && data && data.logsInDateRange && data.logsInDateRange.dataList) {
      const logs = data.logsInDateRange.dataList;
      const warningLogs = logs.filter((log) => log.Log_Type === "Warning");
      const errorLogs = logs.filter((log) => log.Log_Type === "Error");
      const verboseLogs = logs.filter((log) => log.Log_Type === "Verbose");
      const Logs = logs.filter((log) => log.Log_Type === "Log");
      const Debug = logs.filter((log) => log.Log_Type === "Debug");
      const monthsData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
      };

      let sortedWarningLogs = monthsData;
      let sortedErrorLogs = monthsData;
      let sortedVerboseLogs = monthsData;
      let sortedLogs = monthsData;
      let sortedDebug = monthsData;

      warningLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedWarningLogs[createdDateMonth] =
          createdDateMonth && sortedWarningLogs[createdDateMonth] ? parseInt(sortedWarningLogs[createdDateMonth]) + 1 : 1;
      });
      sortedWarningLogs = sortedWarningLogs && Object.keys(sortedWarningLogs).map((log) => sortedWarningLogs[log]);

      Debug.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedDebug[createdDateMonth] =
          createdDateMonth && sortedDebug[createdDateMonth] ? parseInt(sortedDebug[createdDateMonth]) + 1 : 1;
      });
      sortedDebug = sortedDebug && Object.keys(sortedDebug).map((log) => sortedDebug[log]);

      verboseLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedVerboseLogs[createdDateMonth] =
          createdDateMonth && sortedVerboseLogs[createdDateMonth] ? parseInt(sortedVerboseLogs[createdDateMonth]) + 1 : 1;
      });
      sortedVerboseLogs = sortedVerboseLogs && Object.keys(sortedVerboseLogs).map((log) => sortedVerboseLogs[log]);

      errorLogs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedErrorLogs[createdDateMonth] =
          createdDateMonth && sortedErrorLogs[createdDateMonth] ? parseInt(sortedErrorLogs[createdDateMonth]) + 1 : 1;
      });
      sortedErrorLogs = sortedErrorLogs && Object.keys(sortedErrorLogs).map((log) => sortedErrorLogs[log]);

      Logs.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedLogs[createdDateMonth] =
          createdDateMonth && sortedLogs[createdDateMonth] ? parseInt(sortedLogs[createdDateMonth]) + 1 : 1;
      });
      sortedLogs = sortedLogs && Object.keys(sortedLogs).map((log) => sortedLogs[log]);

      setWarningLogs(sortedWarningLogs);
      setErrorLogs(sortedErrorLogs);
      setVerboseLogs(sortedVerboseLogs);
      setLogs(sortedLogs);
      setDebug(sortedDebug);
    }
  }, [handleGetLogs, Start_Date, End_Date]);

  useEffect(() => {
    const element = document.getElementById("kt_advanced_table_widget_5_chart");

    if (!element) {
      return;
    }

    let options = getChartOptions();

    options.series = [
      {
        name: "Warning",
        data: warningLogs,
      },
      {
        name: "Error",
        data: errorLogs,
      },
      {
        name: "Verbose",
        data: verboseLogs,
      },
      {
        name: "Logs",
        data: Logs,
      },
      {
        name: "Debugs",
        data: Debug,
      },
    ];

    options.categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const chart = new ApexCharts(element, options);

    chart.render();

    return function cleanUp() {
      chart.destroy();
    };
  }, [warningLogs, errorLogs, verboseLogs, Logs, Debug]);

  const warningLogsSum = warningLogs.reduce((a, b) => a + b, 0);
  const errorLogsSum = errorLogs.reduce((a, b) => a + b, 0);
  const verboseLogsSum = verboseLogs.reduce((a, b) => a + b, 0);
  const LogsSum = Logs.reduce((a, b) => a + b, 0);
  const debugSum = Debug.reduce((a, b) => a + b, 0);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Logs graph</span>
        </h3>
        <TopRecordSelect numberOfRecords={LogsLimit} changeNumberOfRecords={(e) => setLogsLimit(e.target.value)} />
      </div>
      <div className="card-body pt-3 pb-0 mx-5">
        <div className="row">
          <div className="col-4">
            <div className="table-responsive">
              <table className="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th className="p-0" style={{ width: "50px" }} />
                    <th className="p-0" style={{ minWidth: "200px" }} />
                  </tr>
                </thead>
                <tbody>
                  <TableRow
                    rowColor="warning"
                    rowIcon="flaticon-exclamation-1"
                    rowName="Warning"
                    rowCount={warningLogsSum || 0}
                  />
                  <TableRow rowColor="danger" rowName="Error" rowCount={errorLogsSum || 0} />
                  <TableRow rowColor="primary" rowName="Verbose" rowCount={verboseLogsSum || 0} />
                  <TableRow rowColor="secondary" rowName="Logs" rowCount={LogsSum || 0} />
                  <TableRow rowColor="secondary" rowName="Debug" rowCount={debugSum || 0} />
                </tbody>
              </table>
            </div>
          </div>

          <div className="col-8 p-3 text-center">
            <h3 className="text-info mb-5">Logs by category</h3>
            <div id="kt_advanced_table_widget_5_chart" className="w-100"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function TableRow(props) {
  const { rowColor = "", rowName = "", rowCount = 0 } = props;

  return (
    <tr>
      <td className="pl-0 py-4">
        <div className={`symbol symbol-50 symbol-light-${rowColor} mr-1`}>
          <span className="symbol-label">
            <span className={`text-${rowColor} font-weight-bolder d-block font-size-lg`}>{rowCount}</span>
          </span>
        </div>
      </td>
      <td className="pl-0">{rowName}</td>
    </tr>
  );
}

function getChartOptions() {
  const colors = ["#FFA800", "#F64E60", "#009933", "#333300", "#00ff00"];

  const options = {
    series: [],
    colors: colors,
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [7, 5, 4, 6, 3],
      curve: "smooth",
      colors: colors,
    },
    title: {
      text: "Log Statistics",
      align: "left",
    },
    legend: {
      tooltipHoverFormatter: function(val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + "";
      },
    },
    markers: {
      size: 0,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: [],
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function(val) {
              return val + " (mins)";
            },
          },
        },
        {
          title: {
            formatter: function(val) {
              return val + " per session";
            },
          },
        },
        {
          title: {
            formatter: function(val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return options;
}
