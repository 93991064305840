import React, { useContext, useState } from "react";
// import { useLocation } from "react-router-dom";
// import { toastr } from "react-redux-toastr";
// import { useQuery, useSubscription } from "@apollo/client";
// import store from "../redux/store";
// import { GET_ALL_USER_NOTIFICATIONS, GET_NEW_NOTIFICATION } from './Modules/_Commons/Constants/Queries/notifications';
// import { shallowEqual, useSelector } from 'react-redux';
// import { notificationClient } from '../redux/setupApolloClient';
// import NotificationBell from './Modules/Helpers/NotificationBell';

const NotificationContext = React.createContext();

export function useNotification() {
  return useContext(NotificationContext);
}

export const NotificationConsumer = NotificationContext.Consumer;

export function NotificationProvider({ children }) {
  // const location = useLocation();
  // const { user } = useSelector((state) => state.auth, shallowEqual);
  const [notifications, setNotifications] = useState([]);
  
  // const [client, setClient] = useState(null);

  // useEffect(() => setClient(notificationClient(store)), []);

  // const handleGetAllNotifications = useQuery(GET_ALL_USER_NOTIFICATIONS, { 
  //   client: client,
  //   variables: {
  //     User_ID: user && user.User_ID ? user.User_ID : 0,
  //   } 
  // });
  // const handleGetNewNotification = useSubscription(GET_NEW_NOTIFICATION, { client });

  // React.useEffect(() => {
  //   if (!location.pathname.startsWith("/auth/login")) {
  //     const { loading, error, data } = handleGetAllNotifications;
  //     if (error) toastr.error("Notifications Failed", error.message, NotificationBell);
  //     if (!loading && data) {
  //       const userNotifications = data.userNotifications.filter(notification => user && notification.User_ID === user.User_ID && !notification.Viewed);
  //       setNotifications(userNotifications);
  //     }
  //   }
  // }, [handleGetAllNotifications, user, location]);

  // React.useEffect(() => {
  //   if (!location.pathname.startsWith("/auth/login")) {
  //     const { loading, error, data } = handleGetNewNotification;
  //     if (error) toastr.error("Failed", error.message, NotificationBell);
  //     if (!loading && data) {
  //       const notificationsIds = notifications.map(notification => notification.id);
  //       const userNotification = (user && data.notification && data.notification.User_ID === user.User_ID && !data.notification.Viewed) ? data.notification : {};
  //       if (Object.keys(userNotification).length && !notificationsIds.includes(userNotification.id)) {
  //         toastr.info(userNotification.Title, userNotification.Description, NotificationBell);
  //         setNotifications([userNotification, ...notifications]);
  //       }
  //     }
  //   }
  // }, [handleGetNewNotification, notifications, user, location]);

  return <NotificationContext.Provider value={{ notifications, setNotifications }}>{children}</NotificationContext.Provider>;
}
