import React, { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import ApexCharts from "apexcharts";
import NumberFormat from 'react-number-format';
import { GET_BUDGET_MTEFS } from "../../../../app/Modules/CG/_Commons/Constants/Queries/CgMtef";
import { useQuery } from "@apollo/client";
import { toastr } from "react-redux-toastr";


export function MixedWidget2({ className, tabSelected }) {
  const { activeFiscalYear } = useSelector((state) => state.auth);
  const fiscalYear = !activeFiscalYear ? "" : ((activeFiscalYear.Fiscal_Year.split("-")).map(year => Number(year))).join(" - ") || "Y1";
  
  const [budgetMTEF, setBudgetMTEF] = useState(0);

  const handleGetBudgetMtef = useQuery(GET_BUDGET_MTEFS, {
    skip: !tabSelected
  });

  useEffect(() => {
    const { loading, error, data } = handleGetBudgetMtef;
    if (error) toastr.error("Failed", error.message);
    if (!loading && data && data.budgetMTEF) setBudgetMTEF(data.budgetMTEF);
  }, [handleGetBudgetMtef, tabSelected]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_2_chart");
    if (!element) {
      return;
    }

    let options = getChartOptions();

    options.series = [{
      name: "",
      data: [
        parseInt(budgetMTEF.Y1_Wage),
        parseInt(budgetMTEF.Y1_NonWage), 
        parseInt(budgetMTEF.Y1_Donor), 
        parseInt(budgetMTEF.Y1_AIA), 
        parseInt(budgetMTEF.Y1_GoUDevt),
      ]
    }];

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [budgetMTEF]);

  return (
    <div className={`card card-custom bg-gray-100- ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-danger- py-5">
        <h3 className="card-title font-weight-bolder text-white">Budget MTEF</h3>        
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_2_chart"
          className="card-rounded-bottom bg-danger-"
          style={{ height: "50px" }}
        ></div>
        <div className="text-center">
          <h1 className="text-danger m-0">
            <NumberFormat value={budgetMTEF.Y1_Budget ? Math.round(budgetMTEF.Y1_Budget) : 0} displayType={'text'} thousandSeparator={true} />
            {" "} T
          </h1>
          <p className="text-primary m-0">{fiscalYear} Budget</p>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions() {
  const colors = ["#008ffb", "#56e497", "#feb135", "#f84b5f", "#7760d0"];

  const options = {
    series: [],
    chart: {
      type: 'bar',
      height: 300,
      toolbar: {
        show: false,
        tools:{
          download:false
        }
      },
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      }
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: ["Wage", "NonWage", "Donor", "AIA", "GoU Devt"],
      labels: {
        style: {
          colors: colors,
          fontSize: '12px'
        }
      }
    }
  };
  
  return options;
}
