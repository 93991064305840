import React from "react";
import Select from "react-select";

export default ({
  isDisabled,
  defaultValue,
  value,
  options,
  onChange,
  name,
  placeholder,
  required,
  cascading,
  refInput,
  isLoading,
  isClearable,
}) => (
  <Select
    isDisabled={isDisabled}
    className="basic-single"
    classNamePrefix="select"
    isSearchable={true}
    isClearable={isClearable === false ? isClearable : true}
    options={options}
    onChange={onChange}
    value={value}
    defaultValue={defaultValue}
    name={name}
    placeholder={placeholder}
    required={required}
    cascading={cascading}
    ref={refInput}
    isLoading={isLoading}
  />
);
