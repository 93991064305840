import React from "react";
import objectPath from "object-path";
import { 
  OverlayTrigger, 
  // Tooltip 
} from "react-bootstrap";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
// import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
// import { QuickActionsDropdown } from "../extras/dropdowns/QuickActionsDropdown";
// import { MyCartDropdown } from "../extras/dropdowns/MyCartDropdown";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { useScreenLock } from "../../../../app/screenLock";
import { useNotification } from "../../../../app/Notification";

export function Topbar() {
  const uiService = useHtmlClassService();
  const screenLock = useScreenLock();
  const { notifications } = useNotification();

  const handleLockScreen = async () => {
    screenLock.setIsLocked(true);
  }

  const layoutProps = React.useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);

  return (
    <div className="topbar">
      {/* {layoutProps.viewSearchDisplay && <SearchDropdown />} */}

      {/* {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />} */}

      {/* {layoutProps.viewQuickActionsDisplay && <QuickActionsDropdown />} */}

      {/* {layoutProps.viewCartDisplay && <MyCartDropdown />} */}

      {layoutProps.viewQuickPanelDisplay && (
        <OverlayTrigger
          placement="bottom"
          // overlay={<Tooltip id="quick-panel-tooltip">Quick panel</Tooltip>}
          overlay={<span></span>}
        >
          <div
            className="topbar-item"
            data-toggle="tooltip"
            title="Quick panel"
            data-placement="right"
          >
            <div
              className="btn btn-icon btn-hover-transparent-white btn-lg mr-1"
              id="kt_quick_panel_toggle"
            >
              <span className="svg-icon svg-icon-xl" style={{position: "relative"}}>
                <i className="flaticon2-bell-4 icon-lg text-muted"></i>
                <span 
                  className="badge"
                  style={{
                    background: "#f9b84f",
                    color: "#000",
                    fontWeight: "bold",
                    width: "auto",
                    height: "auto",
                    margin: 0,
                    borderRadius: "50%",
                    position: "absolute",
                    top: "-13px",
                    right: "-8px",
                    padding: "5px",
                  }}
                >
                  {notifications.length || 0}
                </span>
              </span>
            </div>
          </div>
        </OverlayTrigger>
      )}
      
      <OverlayTrigger
        placement="bottom"
        // overlay={<Tooltip id="screen-lock-tooltip">Lock Screen</Tooltip>}
        overlay={<span></span>}
      >
        <div
          className="topbar-item"
          data-toggle="tooltip"
          title="Lock Screen"
          data-placement="right"
          onClick={handleLockScreen}
        >
          <div
            className="btn btn-icon btn-hover-transparent-white btn-lg mr-1"
            id="kt_lock_screen_toggle"
          >
            <span className="svg-icon svg-icon-xl">
              <i className="flaticon2-lock icon-md text-muted"></i>
            </span>
          </div>
        </div>
      </OverlayTrigger>

      {/* {layoutProps.viewLanguagesDisplay && <LanguageSelectorDropdown />} */}

      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  );
}
