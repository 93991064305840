/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";

export function UserProfileDropdown() {
  const { user, userVote, userRole, activeFiscalYear } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto"
          }
        >
          <span className="text-white- opacity-70 font-weight-bold font-size-base d-none d-md-inline mr-1">
            Hi,
          </span>{" "}
          <span className="text-white- opacity-90 font-weight-bolder font-size-base d-none d-md-inline mr-4">
            {user.User_Name}
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <>
          {/** ClassName should be 'dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          {layoutProps.light && (
            <>
              <div className="d-flex align-items-center px-8 pt-8 pb-4 rounded-top">
                <div className="symbol symbol-md bg-light-primary mr-3 flex-shrink-0">
                  <img src={user.pic ? user.pic : toAbsoluteUrl("/media/users/blank.png")} alt="" />
                </div>
                <div className="text-dark m-0 flex-grow-1 mr-3 font-size-h5">
                  {user.First_Name} {user.Last_Name}
                </div>
                <span className="label label-light-success label-lg font-weight-bold label-inline">
                  0 messages
                </span>
              </div>
              
              <div className="separator separator-solid"></div>
              
              <div className="navi navi-spacer-x-0 pt-2">
                { userRole && userRole.Title ? (
                  <div className="navi-item px-8">
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-user-1 icon-lg text-warning font-weight-bold" />
                      </div>
                      <div className="navi-text">
                        <div className="text-warning font-weight-bold">
                          My Role
                        </div>
                        <div className="text-dark">
                          { userRole && userRole.Title }
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : ""
                }
                
                { userVote && userVote.Vote_Code && userVote.Vote_Name ? (
                  <div className="navi-item px-8">
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-correct icon-lg text-success font-weight-bold" />
                      </div>
                      <div className="navi-text text-success">
                        <div className="font-weight-bold">
                          My Vote
                        </div>
                        <div className="text-dark">
                          { userVote && userVote.Vote_Code } - { userVote && userVote.Vote_Name }
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : ""
                }

                { activeFiscalYear && activeFiscalYear.Fiscal_Year ? (
                  <div className="navi-item px-8">
                    <div className="navi-link">
                      <div className="navi-icon mr-2">
                        <i className="flaticon2-calendar-8 icon-lg text-info font-weight-bold" />
                      </div>
                      <div className="navi-text">
                        <div className="font-weight-bold text-info">
                          Fiscal Year
                        </div>
                        <div className="text-dark">
                          { activeFiscalYear && activeFiscalYear.Fiscal_Year }
                        </div>
                      </div>
                    </div>
                  </div>
                  ) : ""
                }
              </div>
              
              <div className="separator separator-solid"></div>
            </>
          )}

          {!layoutProps.light && (
            <div
              className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
              style={{ backgroundImage: `url(${user.pic ? user.pic : toAbsoluteUrl("/media/users/blank.png")})` }}
            >
              <div className="symbol bg-white-o-15 mr-3">
                <span className="symbol-label text-success font-weight-bold font-size-h4">
                  {user.User_Name}
                </span>
                {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
              </div>
              <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
                {user.First_Name} {user.Last_Name}
              </div>
              <span className="label label-success label-lg font-weight-bold label-inline">
                0 messages
              </span>
            </div>
          )}
        </>

        <div className="navi navi-spacer-x-0 pt-5">
          <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
            <div className="navi-link">
              <div className="navi-icon mr-2">
                <i className="flaticon2-calendar-3 icon-lg text-success" />
              </div>
              <div className="navi-text">
                <div className="font-weight-bold cursor-pointer">
                  My Profile &nbsp;
                  <span className="label label-light-danger label-inline font-weight-bold">
                    update
                  </span>
                </div>
                <div className="text-muted">
                  Account settings and more
                </div>
              </div>
            </div>
          </Link>

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer  px-8 py-5">
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              Sign Out
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
