import React, { useEffect, useState} from "react";
import { useSelector } from "react-redux";
import ApexCharts from "apexcharts";
import { useQuery } from "@apollo/client";
import { toastr } from "react-redux-toastr";
import NumberFormat from 'react-number-format';
import { GET_BUDGET_GRANT_IPF } from "../../../../app/Modules/LG/_Commons/Constants/Queries/LgIpfs";


export function MixedWidget3({ className, tabSelected }) {
  const { activeFiscalYear } = useSelector((state) => state.auth);

  const [budgetGrantIpf, setBudgetGrantIpf] = useState(0);
  const [budgetGrantIpfTotal, setBudgetGrantIpfTotal] = useState(0);

  const handleGetBudgetGrantIpf = useQuery(GET_BUDGET_GRANT_IPF, {
    skip: !tabSelected
  });

  useEffect(() => {
    const { loading, error, data } = handleGetBudgetGrantIpf;
    if (error) toastr.error("Failed", error.message);
    if (!loading && data && data.budgetGrantIpf) {
      const budgetGrantIpf = data.budgetGrantIpf;

      const budgetGrantIpfTotal = 
        parseInt(budgetGrantIpf.Projection_Y1) +
        parseInt(budgetGrantIpf.Projection_Y2) + 
        parseInt(budgetGrantIpf.Projection_Y3) + 
        parseInt(budgetGrantIpf.Projection_Y4) + 
        parseInt(budgetGrantIpf.Projection_Y5);
      
      setBudgetGrantIpfTotal(budgetGrantIpfTotal);
      setBudgetGrantIpf(budgetGrantIpf);
    }
  }, [handleGetBudgetGrantIpf, tabSelected]);

  useEffect(() => {
    const element = document.getElementById("kt_mixed_widget_3_chart");
    if (!element) {
      return;
    }

    let options = getChartOptions();

    if (activeFiscalYear) {
      const fiscalYears = [0, 1, 2, 3, 4].map((d, i) => {
        const start = parseInt(activeFiscalYear.Fiscal_Year.substr(0, 4)) + i - 1;
        const end = parseInt(activeFiscalYear.Fiscal_Year.substr(5, 4)) + i - 1;
        return `${start}-${end}`;
      });
  
      options.labels = fiscalYears;
    }

    options.series = [
      parseInt(budgetGrantIpf.Projection_Y1),
      parseInt(budgetGrantIpf.Projection_Y2), 
      parseInt(budgetGrantIpf.Projection_Y3), 
      parseInt(budgetGrantIpf.Projection_Y4), 
      parseInt(budgetGrantIpf.Projection_Y5),
    ];

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [activeFiscalYear, budgetGrantIpf]);

  return (
    <div className={`card card-custom bg-gray-100- ${className}`}>
      {/* Header */}
      <div className="card-header border-0 bg-danger- py-5">
        <h3 className="card-title font-weight-bolder text-white">Budget Grant IPF</h3>        
      </div>
      {/* Body */}
      <div className="card-body p-0 position-relative overflow-hidden">
        {/* Chart */}
        <div
          id="kt_mixed_widget_3_chart"
          className="card-rounded-bottom bg-danger-"
          style={{ height: "50px" }}
        ></div>

        <div className="text-center mt-5">
          <h1 className="text-danger m-0">
            <NumberFormat value={budgetGrantIpfTotal ? Math.round(budgetGrantIpfTotal) : 0} displayType={'text'} thousandSeparator={true} />
            {" "} T
          </h1>
          <p className="text-primary m-0">Budget</p>
        </div>

        {/* Resize */}
        <div className="resize-triggers">
          <div className="expand-trigger">
            <div style={{ width: "411px", height: "461px" }} />
          </div>
          <div className="contract-trigger" />
        </div>
      </div>
    </div>
  );
}

function getChartOptions() {
  const options = {
    series: [],
    labels: ["Y1", "Y2", "Y3", "Y4", "Y5"],
    chart: {
      type: 'donut',
      height: 300,
    },
    plotOptions: {
      pie: {
        // startAngle: -90,
        // endAngle: 90,
        // offsetY: 10
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }]
  };
  
  return options;
}
