import { gql } from "@apollo/client";

const GET_CG_MTEFS = gql`
  query getCgMtefs {
    cgMtefs {
      MTEF_ID
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Prog_By_Vote_ID {
        Prog_By_Vote_ID
        Programme_ID {
          Programme_Name
          Programme_Code
          Programme_ID
        }
        Vote_ID {
          Vote_ID
          Vote_Code
          Vote_Name
        }
      }
      Y0_Wage
      Y0_NonWage
      Y0_GouDevt
      Y0_AIA
      Y0_Donor
      Y1_Wage
      Y1_NonWage
      Y1_GoUDevt
      Y1_AIA
      Y1_Donor
      Y2_Wage
      Y2_NonWage
      Y2_GoUDevt
      Y2_AIA
      Y2_Donor
      Y3_Wage
      Y3_NonWage
      Y3_GoUDevt
      Y3_AIA
      Y3_Donor
      Y4_Wage
      Y4_NonWage
      Y4_GoUDevt
      Y4_AIA
      Y4_Donor
      Y5_Wage
      Y5_NonWage
      Y5_GoUDevt
      Y5_AIA
      Y5_Donor
      MTEF_Version
      Stage
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_CURRENT_CG_MTEFS = gql`
  query getCurrentCgMtefs($Year_ID: Float!, $Stage: Float!) {
    cgCurrentMtefs(Year_ID: $Year_ID, Stage: $Stage) {
      MTEF_ID
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Prog_By_Vote_ID {
        Prog_By_Vote_ID
        Programme_ID {
          Programme_Name
          Programme_Code
          Programme_ID
        }
        Vote_ID {
          Vote_ID
          Vote_Code
          Vote_Name
        }
      }
      Y0_Wage
      Y0_NonWage
      Y0_GouDevt
      Y0_AIA
      Y0_Donor
      Y1_Wage
      Y1_NonWage
      Y1_GoUDevt
      Y1_AIA
      Y1_Donor
      Y2_Wage
      Y2_NonWage
      Y2_GoUDevt
      Y2_AIA
      Y2_Donor
      Y3_Wage
      Y3_NonWage
      Y3_GoUDevt
      Y3_AIA
      Y3_Donor
      Y4_Wage
      Y4_NonWage
      Y4_GoUDevt
      Y4_AIA
      Y4_Donor
      Y5_Wage
      Y5_NonWage
      Y5_GoUDevt
      Y5_AIA
      Y5_Donor
      MTEF_Version
      Stage
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_CG_MTEF_BY_PROG_VOTE_YEAR_STAGE = gql`
  query cgMtefByProgrammeAndVote($Year_ID: Float!, $Stage: Float!, $Programme_ID: Float!, $Vote_ID: Float!) {
    cgMtefByProgrammeAndVote(Programme_ID: $Programme_ID, Vote_ID: $Vote_ID, Year_ID: $Year_ID, Stage: $Stage) {
      MTEF_ID
      Year_ID {
        Year_ID
        Fiscal_Year
      }
      Prog_By_Vote_ID {
        Prog_By_Vote_ID
        Programme_ID {
          Programme_Name
          Programme_Code
          Programme_ID
        }
        Vote_ID {
          Vote_ID
          Vote_Code
          Vote_Name
        }
      }
      Y0_Wage
      Y0_NonWage
      Y0_GouDevt
      Y0_AIA
      Y0_Donor
      Y1_Wage
      Y1_NonWage
      Y1_GoUDevt
      Y1_AIA
      Y1_Donor
      Y2_Wage
      Y2_NonWage
      Y2_GoUDevt
      Y2_AIA
      Y2_Donor
      Y3_Wage
      Y3_NonWage
      Y3_GoUDevt
      Y3_AIA
      Y3_Donor
      Y4_Wage
      Y4_NonWage
      Y4_GoUDevt
      Y4_AIA
      Y4_Donor
      Y5_Wage
      Y5_NonWage
      Y5_GoUDevt
      Y5_AIA
      Y5_Donor
      MTEF_Version
      Stage
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_BUDGET_MTEFS = gql`
  query budgetMTEF {
    budgetMTEF {
      Y1_Wage
      Y1_NonWage
      Y1_Donor
      Y1_AIA
      Y1_GoUDevt
      Y1_Budget
      Y0_Wage
      Y0_NonWage
      Y0_Donor
      Y0_AIA
      Y0_GoUDevt
      Y0_Budget
    }
  }
`;

export { 
  GET_CG_MTEFS, 
  GET_CG_MTEF_BY_PROG_VOTE_YEAR_STAGE, 
  GET_CURRENT_CG_MTEFS,
  GET_BUDGET_MTEFS,
};
