import { useQuery } from "@apollo/client";
import ApexCharts from "apexcharts";
import React, { useEffect, useState } from "react";
import { toastr } from "react-redux-toastr";
import store from "../../../../redux/store";
import { notificationClient } from "../../../../redux/setupApolloClient";
import { GET_FAILED_LOGINS_IN_DATE_RANGE } from "../../../../app/Modules/Administration/_Commons/Constants/Queries/LoginAudit";
import TopRecordSelect from "../../../../app/Modules/Helpers/TopRecordSelect";


export function AdvanceTablesWidget6({ className, Start_Date, End_Date }) {
  const [failedLogins, setFailedLogins] = useState([]);
  const [client, setClient] = useState(null);

  const [FailedLoginsLimit, setFailedLoginsLimit] = useState(1000);

  useEffect(() => setClient(notificationClient(store)), []);

  // const handleGetFailedLogins = useQuery(GET_FAILED_LOGINS, { client });
  const handleGetFailedLogins = useQuery(GET_FAILED_LOGINS_IN_DATE_RANGE, {
    client,
    variables: {
      StartDate: Start_Date,
      EndDate: End_Date,
      Offset: 0,
      Limit: Number(FailedLoginsLimit)
    },
    skip: !(client && Start_Date && End_Date && (End_Date >= Start_Date)),

  });

  useEffect(() => {
    const { loading, error, data } = handleGetFailedLogins;

    if (error) toastr.error("Failed to load failed logins graph data", error.message);
if (error)console.error(JSON.stringify(error, null, 2));
    if (!loading && data && data.failedLoginsInDateRange && data.failedLoginsInDateRange.dataList) {
      // const logins = data.failedLoginsInDateRange;
      // const failedLogins = logins.filter((log) => log.Login_Status === "Failed");
      const failedLogins = data.failedLoginsInDateRange.dataList;
      const monthsData = {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
      };

      let sortedLogins = monthsData;

      failedLogins.forEach((log) => {
        const createdDateMonth = new Date(log.Created_Date).getMonth();
        sortedLogins[createdDateMonth] =
          createdDateMonth && sortedLogins[createdDateMonth] ? parseInt(sortedLogins[createdDateMonth]) + 1 : 1;
      });
      sortedLogins = sortedLogins && Object.keys(sortedLogins).map((log) => sortedLogins[log]);

      setFailedLogins(sortedLogins);
    }
  }, [handleGetFailedLogins, Start_Date, End_Date]);

  useEffect(() => {
    const element = document.getElementById("kt_advanced_table_widget_6_chart");

    if (!element) {
      return;
    }

    let options = getChartOptions();

    options.series = [
      {
        name: "Failed",
        data: failedLogins,
      },
    ];

    options.categories = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const chart = new ApexCharts(element, options);

    chart.render();

    return function cleanUp() {
      chart.destroy();
    };
  }, [failedLogins]);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5 ">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">Failed Logins graph</span>
        </h3>
        <TopRecordSelect numberOfRecords={FailedLoginsLimit} changeNumberOfRecords={(e) => setFailedLoginsLimit(e.target.value)} />
       
      </div>
      <div className="card-body pt-3 pb-0 mx-5">
        <div className="col-12 p-3 text-center">
          <h3 className="text-info mb-5">Failed Login Attempts</h3>
          <div id="kt_advanced_table_widget_6_chart" className="w-100"></div>
        </div>
      </div>
    </div>
  );
}

function getChartOptions() {
  const options = {
    chart: {
      height: 350,
      type: "radar",
    },
    title: {
      text: "Failed Login attempts",
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    },
  };

  return options;
}
