import { gql } from "@apollo/client";

const GET_FISCAL_YEARS = gql`
  query getFiscalYears {
    fiscalYears {
      Year_ID
      Fiscal_Year
      Start_Date
      End_Date
      Status
      Module
      Created_By
      Modified_By
      Created_Date
      Modified_Date
    }
  }
`;

const GET_ACTIVE_FISCAL_YEARS = gql`
  query activeFiscalYears {
    activeFiscalYears {
      Year_ID
      Fiscal_Year
      Start_Date
      End_Date
      Status
      Module
      Created_By
      Modified_By
      Created_Date
      Modified_Date
    }
  }
`;

const GET_PREVIOUS_FISCAL_YEAR = gql`
  query previousFiscalYear {
    previousFiscalYear {
      Year_ID
      Fiscal_Year
      Start_Date
      End_Date
      Status
      Module
      Created_By
      Modified_By
      Created_Date
      Modified_Date
    }
  }
`;

export { GET_FISCAL_YEARS, GET_ACTIVE_FISCAL_YEARS, GET_PREVIOUS_FISCAL_YEAR };
