import { gql } from "@apollo/client";

const GET_AUDITS = gql`
  query getAudits {
    audits {
      Table_Name
      Audit_Type
      Old_Values
      New_Values
      Created_Date
      Created_By
    }
  }
`;

const GET_CREATE_AUDITS = gql`
  query getCreateAudits {
    createAudits {
      Table_Name
      Audit_Type
      Old_Values
      New_Values
      Created_Date
      Created_By
    }
  }
`;

const GET_UPDATE_AUDITS = gql`
  query getUpdateAudits {
    updateAudits {
      Table_Name
      Audit_Type
      Old_Values
      New_Values
      Created_Date
      Created_By
    }
  }
`;

export { GET_AUDITS, GET_CREATE_AUDITS, GET_UPDATE_AUDITS };
