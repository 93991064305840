import React, { useContext, useState } from 'react';

const ScreenLockContext = React.createContext();

export function useScreenLock() {
  return useContext(ScreenLockContext);
}

export const ScreenLockConsumer = ScreenLockContext.Consumer;

export function ScreenLockProvider({ children }) {
  const [isLocked, setIsLocked] = useState(false);
  const value = { isLocked, setIsLocked };

  return (
    <ScreenLockContext.Provider value={value}>
      {children}
    </ScreenLockContext.Provider>
  );
}
