import { gql } from "@apollo/client";

const LOGIN_MUTATION = gql`
  mutation UserLogin($ipAddress: String!, $username: String!, $password: String!) {
    login(data: { ipAddress: $ipAddress, User_Name: $username, Password1: $password }) {
      access_token
      refresh_token
      otpVerification
      user {
        User_ID
        User_Name
        First_Name
        Last_Name
        Email
        Mobile_Number
        Status
        Is_Locked
        Is_Temp_Password
        Multi_Login
        User_Type_ID {
          User_Type_ID
          User_Type_Name
        }
      }
      userMenu {
        Main_Menu_ID {
          Main_Menu_ID
          Main_Menu_Name
          Main_Menu_Icon
          Level
          Status
          Process_Area
          Process_Area_Category
        }
        Sub_Menu_ID
        Sub_Menu_Name
        Sub_Menu_Path
        Level
      }
      activeFiscalYear {
        Year_ID
        Fiscal_Year
        Start_Date
        End_Date
        Status
        Module
        Created_By
        Created_Date
        Modified_By
        Modified_By
      }
      # previousFiscalYear {
      #   Year_ID
      #   Fiscal_Year
      #   Start_Date
      #   End_Date
      #   Status
      #   Module
      #   Created_By
      #   Modified_By
      #   Created_Date
      #   Modified_Date
      # }
      # stages {
      #   Stage_ID
      #   Process_Area
      #   Stage
      #   Status
      #   Created_Date
      #   Modified_Date
      #   Created_By
      #   Modified_By
      # }
      activeStages {
        Stage_ID
        Process_Area
        Stage
        Status
        Created_Date
        Modified_Date
        Created_By
        Modified_By
      }
      userRole {
        Role_ID
        Title
        User_Type_ID {
          User_Type_ID
          User_Type_Name
          Status
        }
        Generate_Permission
        Approve_Permission
        Status
      }
      userVote {
        Vote_ID
        Vote_Code
        Vote_Name
        Status
        Is_AIA
        Is_Virement_Limited
      }
    }
  }
`;

const LOGOUT_MUTATION = gql`
  mutation userLogout($User_ID: Float!) {
    logout(User_ID: $User_ID) {
      User_ID
    }
  }
`;

const REFRESH_TOKENS_MUTATION = gql`
  mutation refreshToken($access_token: String!, $refresh_token: String!) {
    refreshToken(data: { access_token: $access_token, refresh_token: $refresh_token }) {
      access_token
      refresh_token
    }
  }
`;

const RESET_FORGOT_PASSWORD_MUTATION = gql`
  mutation resetForgotPassword($email: String!) {
    resetForgotPassword(data: { email: $email }) {
      reset
    }
  }
`;

const VERIFY_OTP_CODE_MUTATION = gql`
  mutation verifyOtpCode($code: String!) {
    verifyOtpCode(data: { code: $code }) {
      valid
      used
      userId
    }
  }
`;

export { LOGIN_MUTATION, LOGOUT_MUTATION, REFRESH_TOKENS_MUTATION, RESET_FORGOT_PASSWORD_MUTATION, VERIFY_OTP_CODE_MUTATION };
