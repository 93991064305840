import React from "react";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

export default ({ numberOfRecords, changeNumberOfRecords }) => (
  <Form.Group as={Row} controlId="Fiscal_Year">
    <Form.Label column sm="4">
      Top
    </Form.Label>
    <Col sm="8">
      <Form.Control
        as="select"
        custom
        defaultValue={numberOfRecords ? numberOfRecords : 1000}
        onChange={changeNumberOfRecords}
        required
      >
        <option value={1000}>1,000</option>
        <option value={5000}>5,000</option>
        <option value={10000}>10,000</option>
        <option value={50000}>50,000</option>
        <option value={100000}>100,000</option>
      </Form.Control>
    </Col>
  </Form.Group>
);
