import { gql } from "@apollo/client";

const GET_REPORTS = gql`
  query getReports {
    reports {
      Report_ID
      Report_Category
      Report_Name
			Internal_Report_Name
			Report_Format
			Report_Type
			Stage
			Report_Path
			Level_of_Approval
			Report_Quarter
			Is_Dashboard
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_REPORTS_BY_TYPE = gql`
  query getReportsByType($Report_Type: String!) {
    reportsByType(Report_Type: $Report_Type) {
      Report_ID
      Report_Category
      Report_Name
			Internal_Report_Name
			Report_Format
			Report_Type
			Stage
			Report_Path
			Level_of_Approval
			Report_Quarter
			Is_Dashboard
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_REPORTS_BY_TYPE_AND_CATEGORY = gql`
  query getReportsByTypeAndCategory(
    $Report_Type: String!,
    $Report_Category: String!
  ) {
    reportsByTypeAndCategory(
      Report_Type: $Report_Type,
      Report_Category: $Report_Category
    ) {
      Report_ID
      Report_Category
      Report_Name
			Internal_Report_Name
			Report_Format
			Report_Type
			Stage
			Report_Path
			Level_of_Approval
			Report_Quarter
			Is_Dashboard
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const GET_USER_ASSIGNED_TO_GENEATE_REPORTS = gql`
  query getUserAssignedToGenerateReports(
    $Vote_ID: Int!,
    $Year_ID: Int!,
    $Stage: Int!,
  ) {
    userAssignedToGenerateReports(
      Vote_ID: $Vote_ID,
      Year_ID: $Year_ID,
      Stage: $Stage,
    ) {
      Report_ID
      Report_Category
      Report_Name
			Internal_Report_Name
			Report_Format
			Report_Type
			Stage
			Report_Path
			Level_of_Approval
			Report_Quarter
			Is_Dashboard
      Status
      Created_Date
      Modified_Date
      Created_By
      Modified_By
    }
  }
`;

const COUNT_REPORTS = gql`
  query countReports {
    countReports {
      Report_Count
      Report_Category
      Report_Type
    }
  }
`;

const COUNT_ACTIVE_REPORTS = gql`
  query countActiveReports {
    countActiveReports {
      count
    }
  }
`;

const COUNT_INACTIVE_REPORTS = gql`
  query countInActiveReports {
    countInActiveReports {
      count
    }
  }
`;

const CHECK_REPORT_LEVEL_USER_ASSIGNMENTS = gql`
  query checkReportLevelUserAssignments($Report_ID: Int!,) {
    checkReportLevelUserAssignments(Report_ID: $Report_ID) {
      message
    }
  }
`;

const CHECK_CONSISTANCE_REPORT_BALANCE = gql`  
  query checkConsistanceReportBalance(
    $Vote_ID: Int!,
    $Year_ID: Int!,
    $Stage: Int!,
  ) {
    checkConsistanceReportBalance(
      Vote_ID: $Vote_ID,
      Year_ID: $Year_ID,
      Stage: $Stage,
    ) {
      balancing
    }
  }
`;

const CHECK_AVAILABLE_VIREMENT_REPORT_BALANCE = gql`
  query checkAvailableVirementBalanceReportBalance(
    $Year_ID: Float!,
    $Vote_Code: String!,
  ) {
    checkAvailableVirementBalanceReportBalance(
      Year_ID: $Year_ID,
      Vote_Code: $Vote_Code,
    ) {
      balancing
    }
  }
`;

const GET_ARCHIVED_REPORTS = gql`
  query getArchivedReports {
    getArchivedReports {
      Fiscal_Year
      Report_Name
      Vote_Code
      Vote_Name
      Parastatal_Code
      Parastatal_Name
      Report_Category
      Is_Archived
      Report_File
      Created_Date
    }
  }
`;

const GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_STAGE = gql`
  query getArchivedReportsByTypeYearAndStage(
    $type: String!,
    $year: Float!,
    $stage: Float!,
  ) {
    getArchivedReportsByTypeYearAndStage(
      type: $type,
      year: $year,
      stage: $stage,
    ) {
      Fiscal_Year
      Report_Name
      Vote_Code
      Vote_Name
      Parastatal_Code
      Parastatal_Name
      Report_Category
      Is_Archived
      Report_File
      Created_Date
    }
  }
`;

const GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_VOTE = gql`
  query getArchivedReportsByTypeYearAndVote(
    $type: String!,
    $year: Float!,
    $vote: Float!,
  ) {
    getArchivedReportsByTypeYearAndVote(
      type: $type,
      year: $year,
      vote: $vote,
    ) {
      Fiscal_Year
      Report_Name
      Vote_Code
      Vote_Name
      Parastatal_Code
      Parastatal_Name
      Report_Category
      Is_Archived
      Report_File
      Created_Date
    }
  }
`;

const CHECK_IS_REPORT_INITIATED = gql`
  query checkIsReportInitiated(
    $Internal_Report_Name: String!,
    $Report_Category: String!,
    $Report_Type: String!,
    $Vote_ID: Float!,
    $Year_ID: Float!,
    $Previous_Year_ID: Float!,
    #$Sepc_ID: Float,
  ) {
    checkIsReportInitiated(
      Internal_Report_Name: $Internal_Report_Name,
      Report_Category: $Report_Category,
      Report_Type: $Report_Type,
      Vote_ID: $Vote_ID,
      Year_ID: $Year_ID,
      Previous_Year_ID: $Previous_Year_ID,
      #Sepc_ID: $Sepc_ID,
    ) {
      Is_Initiated
    }
  }
`;

export {
  GET_REPORTS,
  GET_REPORTS_BY_TYPE,
  COUNT_REPORTS,
  COUNT_ACTIVE_REPORTS,
  COUNT_INACTIVE_REPORTS,
  GET_REPORTS_BY_TYPE_AND_CATEGORY,
  GET_USER_ASSIGNED_TO_GENEATE_REPORTS,
  CHECK_REPORT_LEVEL_USER_ASSIGNMENTS,
  CHECK_CONSISTANCE_REPORT_BALANCE,
  CHECK_AVAILABLE_VIREMENT_REPORT_BALANCE,
  GET_ARCHIVED_REPORTS,
  GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_STAGE,
  GET_ARCHIVED_REPORTS_BY_TYPE_YEAR_AND_VOTE,
  CHECK_IS_REPORT_INITIATED
};
