import React from "react";
import { isNull, isUndefined } from "lodash-es";

export const EqualArrays = (first, second) => {
  if (first.length !== second.length) {
    return false;
  }
  for (let i = 0; i < first.length; i++) {
    if (!second.includes(first[i])) {
      return false;
    }
  }
  return true;
};

// export const AgGridNumberParser = (params) => Number(params.newValue);
export const AgGridNumberParser = (params) => params.newValue;

export const AgGridValueFormatter = (params) => (params.value === 0 ? params.value.toString() : params.value);

export const currencyFormatter = (params) => formatNumber(params.value);

// const formatNumber=(number) =>((number).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"));

function formatNumber(n) {
  var parts = n
    .toFixed(3)
    .toString()
    .split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
}

export const cgcurrencyFormatter = (params) => cgformatNumber(params.value);
export const lgcurrencyFormatter = (params) => lgformatNumber(params.value);
export const thousandsFormatter = (params) => formatThousands(params.value);

function cgformatNumber(n) {
  if (isNull(n) || isUndefined(n) || isNaN(n)) return null;
  var parts = parseFloat(n)
    .toFixed(9)
    // .toString()
    .split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  // if (parts[1]) {
  //   let decimalPart;
  //   if (parts[1].length >= 9) {
  //     decimalPart = parts[1].slice(0, 9);
  //   } else {
  //     decimalPart = parts[1];
  //     for (let i = parts[1].length; i < 9; i++) {
  //       decimalPart += "0";
  //     }
  //   }
  //   return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + decimalPart;
  //   // return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  // } else {
  //   return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".000000000";
  // }
}

export const lgformatNumber = (n) => {
  if (isNull(n) || isUndefined(n) || isNaN(n)) return null;
  var parts = parseFloat(n)
    .toFixed(3)
    // .toString()
    .split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  // if (parts[1]) {
  //   let decimalPart;
  //   if (parts[1].length >= 3) {
  //     decimalPart = parts[1].slice(0, 3);
  //   } else {
  //     decimalPart = parts[1];
  //     for (let i = parts[1].length; i < 3; i++) {
  //       decimalPart += "0";
  //     }
  //   }
  //   return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + decimalPart;
  //   // return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  // } else {
  //   return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ".000";
  // }
}

export const formatThousands = (n) => {
  if (isNull(n) || isUndefined(n) || isNaN(n)) return null;
  var parts = parseFloat(n)
    .toString()
    .split(".");
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
};

export const compareDropDownOptionsAscending = (a, b) => {
  if (a.label < b.label) {
    return -1;
  } else if (a.label > b.label) {
    return 1;
  }
  return 0;
};

export const arrayUniqueByKey = (array, key) => [...new Map(array.map((item) => [item[key], item])).values()];

export const textAreaCounter = (text, max) => {
  const textLength = (text && text.length) || 0;
  return (
    <div className="w-100 text-right my-1">
      <span className="font-weight-bold">
        <small className="font-weight-normal text-danger">{textLength}</small>/{max}
      </span>
    </div>
  );
};

export const groupBy = (listData, groupByKey) => {
  return listData.reduce((acc, item) => {
    (acc[item[groupByKey]] = acc[item[groupByKey]] || []).push(item);
    return acc;
  }, {});
};

